import { Layout } from 'antd'
import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { mainRoutes } from '../utils/routes/main/mainRoutes'
import Sidebar from '../components/sidebar/Sidebar'
import Topbar from '../components/topbar/Topbar'
import { rootPaths } from '../utils/routes/root/rootPaths'
import { mainPaths } from '../utils/routes/main/mainPaths'
import { AuthService } from '../services/AuthService'
import PageNotFound from '../pages/page-not-found/PageNotFound'
import BossRoutes from '../utils/routes/main/BossRoutes'
import { protectedRoutes } from '../utils/routes/main/protectedRoutes'
import DeveloperRoutes from '../utils/routes/main/DeveloperRoutes'
import Companies from '../pages/developer/companies-page/Companies'
import NotDeveloperRoutes from '../utils/routes/main/NotDeveloperRoutes'
import DetailedCompany from '../components/companies/DetailedCompany'

const MainRouter: React.FC = () => {
  const { Content } = Layout
  const { pathname } = useLocation()

  const redirect = () => {
    if (AuthService.getToken()) {
      if (!AuthService.isDeveloper()) {
        if (pathname === rootPaths.main) {
          return <Navigate to={rootPaths.main + mainPaths.commerce} />
        }
      } else {
        if (pathname === rootPaths.main) {
          return <Navigate to={rootPaths.main + mainPaths.companies} />
        }
      }
      return null
    } else {
      return <Navigate to={rootPaths.login} />
    }
  }

  return (
    <div className="main">
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar />
        <Layout className="site-layout">
          <Content>
            <Topbar />
            <Routes>
              <Route element={<NotDeveloperRoutes />}>
                {mainRoutes.map((route) => (
                  <Route
                    element={<route.element />}
                    path={`${route.path}/*`}
                    key={route.path}
                  />
                ))}
                <Route element={<BossRoutes />}>
                  {protectedRoutes.map((route) => (
                    <Route
                      element={<route.element />}
                      path={`${route.path}/*`}
                      key={route.path}
                    />
                  ))}
                </Route>
              </Route>
              <Route element={<DeveloperRoutes />}>
                <Route element={<Companies />} path={mainPaths.companies} />
                <Route
                  element={<DetailedCompany />}
                  path={mainPaths.detailed_company}
                />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
      {redirect()}
    </div>
  )
}

export default MainRouter
