import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useCreateCompany } from '../../queries/mutations'
import { CreateCompanyData } from '../../utils/models/companies-page/Companies'

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const CompanyModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const createCompany = useCreateCompany(setVisible)
  const { Item } = Form
  const [form] = Form.useForm()

  // on cancel
  const onCancel = () => {
    setVisible(false)
  }

  // on finish
  const onFinish = (values: CreateCompanyData) => {
    createCompany.mutate(values)
  }

  // after close
  const afterClose = () => {
    form.resetFields()
  }

  return (
    <Modal
      title="Create new company"
      visible={visible}
      onCancel={() => setVisible(false)}
      centered
      width={600}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={createCompany.isLoading}
          type="primary"
          htmlType="submit"
          form="company_create"
        >
          Create
        </Button>,
      ]}
      afterClose={afterClose}
    >
      <Form
        labelCol={{ span: 7 }}
        id="company_create"
        autoComplete="off"
        onFinish={onFinish}
        form={form}
      >
        <Item
          label="Company name"
          name="station_name"
          rules={[{ required: true, message: 'Enter company name!' }]}
        >
          <Input />
        </Item>
        <Item
          label="Director name"
          name="name"
          rules={[{ required: true, message: 'Enter director name!' }]}
        >
          <Input />
        </Item>
        <Item
          label="Login"
          name="login"
          rules={[{ required: true, message: 'Enter login!' }]}
        >
          <Input />
        </Item>
        <Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Enter password!',
            },
            {
              min: 5,
            },
          ]}
          hasFeedback
          label={'Password'}
        >
          <Input.Password />
        </Item>

        <Item
          name="reset_password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!'
                )
              },
            }),
          ]}
          label={'Confirm password'}
        >
          <Input.Password />
        </Item>
      </Form>
    </Modal>
  )
}

export default CompanyModal
