import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import { Area, AreaChart, Bar, BarChart, Tooltip } from 'recharts'
import ArrowBottom from '../../assets/svg/ArrowBottom'
import ArrowTop from '../../assets/svg/ArrowTop'
import {
  useAnalyticsDiscClient,
  useAnalyticsDiscCollection,
  useAnalyticsDiscPayment,
  useAnalyticsDiscVisit,
} from '../../queries/queries'

function AnalyticsTop() {
  const {
    data: dataCollection,
    isLoading: collectionLoading,
    isFetching: collectionFetching,
  } = useAnalyticsDiscCollection()
  const {
    data,
    isLoading: visitLoading,
    isFetching: visitFetching,
  } = useAnalyticsDiscVisit()
  const {
    data: dataPayments,
    isLoading: paymentLoading,
    isFetching: paymentFetching,
  } = useAnalyticsDiscPayment()
  const {
    data: dataClient,
    isLoading: clientLoading,
    isFetching: clientFetching,
  } = useAnalyticsDiscClient()
  const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />

  const barChartWidth = () => {
    // if (dataPayments?.data) {
    //   return dataPayments?.data?.length * 15;
    // } else return 750;
    return document.querySelector('.chartsDiv')?.clientWidth
  }

  return (
    <div className="analytics_top">
      {/* Discount Collection */}
      <div className="discount-accumulate card">
        <Spin spinning={collectionLoading}>
          <div className="card_header">
            <div className="title">
              Jamg'arilgan keshbek (Bugungi)&nbsp;
              <span className="fetching_loader">
                <Spin indicator={antIcon} spinning={collectionFetching} />
              </span>
            </div>
          </div>
          <h3>{dataCollection?.today_cashback?.toLocaleString() || 0} so'm</h3>
          <p>
            Sarf {Math.abs(dataCollection?.spend || 0)}% &nbsp;
            <span>
              {dataCollection?.spend! > 0 ? <ArrowTop /> : <ArrowBottom />}
            </span>
          </p>
          <p>
            Jamg'arma {Math.abs(dataCollection?.collect || 0)}% &nbsp;
            <span>
              {dataCollection?.collect! > 0 ? <ArrowTop /> : <ArrowBottom />}
            </span>
          </p>
          <hr className="line" />
          <p>
            Jami:&nbsp;{dataCollection?.all_cashback?.toLocaleString() || 0} so'm
          </p>
        </Spin>
      </div>

      {/* Discount Visit */}
      <div className="card discount-visit">
        <Spin spinning={visitLoading}>
          <div className="card_header">
            <div className="title">
              To'lovlar (Bugungi)&nbsp;
              <span className="fetching_loader">
                <Spin indicator={antIcon} spinning={visitFetching} />
              </span>
            </div>
          </div>
          <h3>{data?.today_visit || 0} ta</h3>
          <div className="chartsDiv">
            <AreaChart
              width={barChartWidth()}
              height={44}
              data={data?.data}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <Tooltip
                content={(tooltip: any) => {
                  return (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '0.5rem',
                        border: '1px solid rgb(187, 184, 184)',
                      }}
                    >
                      {tooltip?.payload ? (
                        <span>
                          {dayjs(
                            tooltip?.payload[0]?.payload?.date,
                            'DD-MM-YYYY'
                          ).format('DD.MM.YYYY')}
                        </span>
                      ) : null}
                      <br />
                      {tooltip?.payload ? (
                        <span>
                          To'lovlar: {tooltip?.payload[0]?.payload?.count || 0} ta
                        </span>
                      ) : null}
                    </div>
                  )
                }}
              />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#975fe4"
                fill="#975fe4"
              />
            </AreaChart>
          </div>
          <hr className="line" />
          <p>Jami: {data?.all_visit || 0} ta</p>
        </Spin>
      </div>

      {/* Discount Payments */}
      <div className="card">
        <Spin spinning={paymentLoading}>
          <div className="card_header">
            <div className="title">
              Cashback orqali to'lovlar (Bugungi) &nbsp;
              <span className="fetching_loader">
                <Spin indicator={antIcon} spinning={paymentFetching} />
              </span>
            </div>
          </div>
          <h3>{dataPayments?.today_payment || 0} ta</h3>
          <div className="chartsDiv">
            <BarChart
              width={barChartWidth()}
              height={44}
              data={dataPayments?.data}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
              }}
              barSize={12}
            >
              <Tooltip
                content={(tooltip: any) => {
                  return (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '0.5rem',
                        border: '1px solid rgb(187, 184, 184)',
                      }}
                    >
                      {tooltip?.payload ? (
                        <span>
                          {dayjs(
                            tooltip?.payload[0]?.payload?.date,
                            'DD-MM-YYYY'
                          ).format('DD.MM.YYYY')}
                        </span>
                      ) : null}
                      <br />

                      {tooltip?.payload ? (
                        <span>
                          To'lovlar: {tooltip?.payload[0]?.payload?.count || 0} ta
                        </span>
                      ) : null}
                    </div>
                  )
                }}
              />
              <Bar type="monotone" dataKey="count" stroke="#1890FF" fill="#1890FF" />
            </BarChart>
          </div>
          <hr className="line" />
          <p>Jami: {dataPayments?.all_payment || 0} ta</p>
        </Spin>
      </div>

      {/* Discount Client  */}

      <div className="card">
        <Spin spinning={clientLoading}>
          <div className="card_header">
            <div className="title">
              Mijozlar (Bugungi) &nbsp;
              <span className="fetching_loader">
                <Spin indicator={antIcon} spinning={clientFetching} />
              </span>
            </div>
          </div>
          <h3>{dataClient?.today_client || 0} ta</h3>
          <div className="chartsDiv">
            <BarChart
              width={barChartWidth()}
              height={44}
              data={dataClient?.data}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
              }}
              barSize={12}
            >
              <Tooltip
                content={(tooltip: any) => {
                  return (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '0.5rem',
                        border: '1px solid rgb(187, 184, 184)',
                      }}
                    >
                      {tooltip?.payload ? (
                        <span>
                          {dayjs(
                            tooltip?.payload[0]?.payload?.date,
                            'DD-MM-YYYY'
                          ).format('DD.MM.YYYY')}
                        </span>
                      ) : null}
                      <br />

                      {tooltip?.payload ? (
                        <span>
                          Mijozlar: {tooltip?.payload[0]?.payload?.count} ta
                        </span>
                      ) : null}
                    </div>
                  )
                }}
              />
              <Bar type="monotone" dataKey="count" stroke="#1890FF" fill="#1890FF" />
            </BarChart>
          </div>
          <hr className="line" />
          <p>Jami: {dataClient?.all_client || 0} ta</p>
        </Spin>
      </div>
    </div>
  )
}

export default AnalyticsTop
