import { addControllerPrefix } from '../helpers/addControllerPrefix'

export const urlsWithoutEndpoints = {
  client: '/client',
  login: '/login',
  createAndUpdateStaff: '/register',
  mainSettings: '/station',
  payment: '/payment',
  clientWithBarcode: '/barcode',
  profile: '/profile',
}

export const controllers = {
  COMPANY: '/company',
  LOGIN: '/login',
  STAFF: '/user',
  STATS: '/statistics',
  SMS: '/sms',
}

// companies
const company = {
  INDEX: '',
  ANALYTICS: '/statistics',
  CREATE: '/create',
}
export const companyEndpoints: typeof company = addControllerPrefix(
  company,
  controllers.COMPANY
)

// staffs
const staffs = {
  INDEX: '',
}
export const staffEndpoints: typeof staffs = addControllerPrefix(
  staffs,
  controllers.STAFF
)

//statistics
const stats = {
  visitDiscount: '/visitDiscount',
  collectionDiscount: '/collectionDiscount',
  paymentDiscount: '/paymentDiscount',
  clientDiscount: '/clientDiscount',
  generalStats: '/general',
  lastPayment: '/lastPayment',
  bestAccountant: '/bestAccountant',
  lastPaymentTable: '/lastPaymentTable',
}
export const statsEndpoints: typeof stats = addControllerPrefix(
  stats,
  controllers.STATS
)

// statistics general
const generalStats = {
  payment: '/payment',
  visit: '/visit',
}
export const generalStatsEndpoints: typeof generalStats = addControllerPrefix(
  generalStats,
  statsEndpoints.generalStats
)

// SMS
const sms = {
  INDEX: '',
  KEYS: '/keys',
  SPECIAL: '/special',
  HISTORY: '/history',
}
export const smsEndpoints: typeof sms = addControllerPrefix(sms, controllers.SMS)
