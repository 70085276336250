import { Button, Form, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useUpdateSettingsMain } from '../../queries/mutations'
import { useMainSettings } from '../../queries/queries'
import { SettingsMainData } from '../../utils/models/settings-page/SettingsMain'

const SettingsMain: React.FC = () => {
  const { data, isLoading } = useMainSettings()
  const updateSettingMain = useUpdateSettingsMain()
  const [form] = Form.useForm()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        amount: data.amount,
        discount: data.discount,
      })
    }
  }, [data, form])

  const onFinish = (fields: SettingsMainData) => {
    const dataUpdate = {
      amount: fields.amount,
      discount: fields.discount,
    }
    updateSettingMain.mutate(dataUpdate)
  }

  return (
    <div className="white_box mainSettings">
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onFinish} className="df mainSettings_form">
          <Form.Item
            className=" inputSettings "
            rules={[
              { required: true, message: 'Iltimos chegirma kiriting!' },
              () => ({
                validator(_, value) {
                  if (value > 100) {
                    return Promise.reject(
                      new Error("Chegirma 100% dan katta bo'lishi mumkin emas!")
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
            name="discount"
            label="Discount foizi:"
          >
            <Input type="number" min={1} max={100} />
          </Form.Item>
          <Form.Item
            className=" inputSettings "
            name="amount"
            label="Maksimum to'lov :"
            rules={[
              { required: true, message: "Iltimos maksmum to'lovni kiriting" },
              () => ({
                validator(_, value) {
                  if (value > 500000) {
                    return Promise.reject(
                      new Error(
                        "Maksimum to'lov 500 000 so'mdan yuqori bo'lishi mumkin emas!"
                      )
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              type="number"
              // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            />
          </Form.Item>
          <Form.Item className="buttonSave">
            <Button htmlType="submit" type="primary">
              Saqlash
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}

export default SettingsMain
