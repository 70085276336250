import React from 'react'

const Logo: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 16C1.5 10.0836 2.50568 6.61629 4.56099 4.56098C6.61629 2.50568 10.0836 1.5 16 1.5C21.9164 1.5 25.3837 2.50568 27.439 4.56098C29.4943 6.61629 30.5 10.0836 30.5 16C30.5 21.9164 29.4943 25.3837 27.439 27.439C25.3837 29.4943 21.9164 30.5 16 30.5C10.0836 30.5 6.61629 29.4943 4.56099 27.439C2.50568 25.3837 1.5 21.9164 1.5 16Z"
        fill="#789EFF"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5542 20.445L20.445 11.5542"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3161 20.3241H20.3317"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6675 11.6755H11.6831"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Logo
