import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateStaff } from '../../queries/mutations'
import { modalTypes } from '../../utils/constants/modalTypes'
import {
  StaffsFormFields,
  StaffsModalData,
  StaffsPayloadData,
} from '../../utils/models/settings-page/Staffs'

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  data: StaffsModalData
}

const SettingsStaffsModal: React.FC<IProps> = ({ visible, setVisible, data }) => {
  const [form] = Form.useForm()
  const createUpdateStaff = useCreateUpdateStaff(setVisible, form)
  const { Item } = Form
  const [changingPassword, setChangingPassword] = useState(false)
  const { t } = useTranslation()

  // default values
  useEffect(() => {
    if (data.data && data.type === modalTypes.UPDATE) {
      form.setFieldsValue({
        name: data.data.name,
        login_org: data.data.login,
      })
    }
  }, [data, form])

  // on cancel
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  // on finish
  const onFinish = (values: StaffsFormFields) => {
    let payloadData: StaffsPayloadData = {
      login: values.login_org,
      name: values.name,
      password: values.password_org,
      reset_password: values.confirm,
    }
    if (data?.data?.id) {
      payloadData = { ...payloadData, id: data?.data?.id }
    }
    createUpdateStaff.mutate(payloadData)
  }

  // enable/disable password fields
  const enableChangingPassword = () => {
    setChangingPassword((prev) => !prev)
  }

  // available password fields
  const isAvailablePasswordFields = () => {
    if (changingPassword || data.type === modalTypes.CREATE) {
      return true
    }
    return false
  }

  // modal title
  const modalTitle = () => {
    if (data.type === modalTypes.CREATE) {
      return t("settings.Yangi xodim qo'shish")
    } else if (data.type === modalTypes.UPDATE) {
      return t("settings.Xodim ma'lumotlarini yangilash")
    }
    return ''
  }

  // submit button text
  const submitButtonText = () => {
    if (data.type === modalTypes.CREATE) {
      return t("Qo'shish")
    } else if (data.type === modalTypes.UPDATE) {
      return t('Yangilash')
    }
  }

  return (
    <Modal
      visible={visible}
      centered
      title={modalTitle()}
      onCancel={onCancel}
      className="settings_staffs_modal"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('Bekor qilish')}
        </Button>,
        <Button
          key="submit"
          loading={createUpdateStaff.isLoading}
          type="primary"
          htmlType="submit"
          form="staff_form"
        >
          {submitButtonText()}
        </Button>,
      ]}
    >
      <Form
        id="staff_form"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="fake">
          <Input placeholder="login" name="login" />
          <Input.Password placeholder="password" name="password" />
        </div>
        <Item
          name="name"
          rules={[
            {
              required: true,
              message: t('settings.errors.name'),
            },
          ]}
          label={t('settings.Xodim ismi')}
        >
          <Input />
        </Item>
        <Item
          name="login_org"
          rules={[
            {
              required: true,
              message: t('settings.errors.login'),
            },
            {
              min: 5,
              message: "Login kamida 5ta belgidan iborat bo'lishi kerak!",
            },
          ]}
          label={t('settings.Login')}
        >
          <Input />
        </Item>

        {isAvailablePasswordFields() && (
          <>
            <Item
              name="password_org"
              rules={[
                {
                  required: true,
                  message: t('settings.errors.password'),
                },
                {
                  min: 5,
                  message: "Parol kamida 5ta belgidan iborat bo'lishi kerak!",
                },
              ]}
              hasFeedback
              label={t('settings.Parol')}
            >
              <Input.Password />
            </Item>

            <Item
              name="confirm"
              dependencies={['password_org']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('settings.errors.confirm'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password_org') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('settings.errors.not_match')))
                  },
                }),
              ]}
              label={t('settings.Parolni tasdiqlash')}
            >
              <Input.Password />
            </Item>
          </>
        )}
        {data.type === modalTypes.UPDATE && (
          <p className="enableChangingPassword" onClick={enableChangingPassword}>
            {t("settings.Parolni o'zgartirish")}
          </p>
        )}
      </Form>
    </Modal>
  )
}

export default SettingsStaffsModal
