import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Popconfirm, Spin, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteStaff } from '../../queries/mutations'
import { useStaffs } from '../../queries/queries'
import { modalTypes } from '../../utils/constants/modalTypes'
import { StaffsData, StaffsModalData } from '../../utils/models/settings-page/Staffs'
import SettingsStaffsModal from './SettingsStaffsModal'

const SettingsStaffs: React.FC = () => {
  const { Column } = Table
  const [form] = Form.useForm()
  const timerRef = React.useRef<any>()
  const [searchText, setSearchText] = useState('')
  const { data, isLoading } = useStaffs(searchText)
  const deleteStaffMutatiton = useDeleteStaff()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState<StaffsModalData>({ type: '' })
  const { t } = useTranslation()

  // create staff
  const createStaff = () => {
    setModalData({ type: modalTypes.CREATE })
    setModalVisible(true)
  }

  // update staff
  const updateStaff = (record: StaffsData) => {
    setModalData({
      type: 'update',
      data: {
        id: record.id,
        name: record.name,
        login: record.login,
      },
    })
    setModalVisible(true)
  }

  // delete staff
  const deleteStaff = (record: StaffsData) => {
    deleteStaffMutatiton.mutate(record.id)
  }

  return (
    <>
      <div className="white_box search">
        <div className="search_left">
          <p>
            <span>{t('settings.Xodim ismi')}</span>
            <QuestionCircleOutlined />
            <span className="third">:</span>
          </p>
          <Form form={form}>
            <Form.Item name="searchText">
              <Input
                width={400}
                onChange={(e) => {
                  clearTimeout(timerRef.current)
                  timerRef.current = setTimeout(() => {
                    setSearchText(e.target.value.length > 2 ? e.target.value : '')
                  }, 500)
                }}
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="search_right">
          <Button type="primary" onClick={createStaff}>
            + {t("Yangi qo'shish")}
          </Button>
        </div>
      </div>
      <div className="white_box staffs">
        <Table
          pagination={false}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
        >
          <Column title="№" render={(_, __, index) => <span>{index + 1}</span>} />
          <Column title={t('Xodimlar')} dataIndex="name" width={500} />
          <Column title={t('settings.Login')} dataIndex="login" />
          <Column
            title={t('Tizimga kiritildi')}
            align="center"
            render={(record) => (
              <span>
                {dayjs(record.created_at * 1000).format('DD.MM.YYYY HH:mm')}
              </span>
            )}
          />
          <Column
            title={() => (
              <>
                <span>{t('Action')}</span>
                &nbsp;
                {deleteStaffMutatiton.isLoading && (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                  />
                )}
              </>
            )}
            align="center"
            render={(record) => (
              <div className="more">
                <Button onClick={() => updateStaff(record)}>
                  <EditOutlined />
                </Button>
                <Popconfirm
                  title={t("settings.Xodimni o'chirmoqchimisiz?")}
                  onConfirm={() => deleteStaff(record)}
                  okText={t('Ha')}
                  cancelText={t("Yo'q")}
                  placement="left"
                >
                  <Button>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
            )}
          />
        </Table>
        <SettingsStaffsModal
          visible={modalVisible}
          setVisible={setModalVisible}
          data={modalData}
        />
      </div>
    </>
  )
}

export default SettingsStaffs
