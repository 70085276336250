import { LoadingOutlined } from '@ant-design/icons'
import { Space, Spin, Tabs } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useGeneralPaymentStats, useGeneralVisitStats } from '../../queries/queries'
import AppDatePicker from '../../utils/helpers/AppDatePicker'

const AnalyticsCenter = () => {
  const { RangePicker } = AppDatePicker
  const [dateType, setDateType] = useState<'date' | 'month' | 'year'>('date')
  const { TabPane } = Tabs
  const [rangePickerValue, setRangePickerValue] = useState<any>([
    dayjs().startOf('month'),
    dayjs(),
  ])

  const {
    data,
    isLoading: paymentLoading,
    isFetching: paymentFetching,
  } = useGeneralPaymentStats(rangePickerValue, dateType)
  const {
    data: dataVisit,
    isLoading: visitLoading,
    isFetching: visitFetching,
  } = useGeneralVisitStats(rangePickerValue, dateType)

  // range picker format
  const rangePickerFormat = () => {
    if (dateType === 'date') {
      return ['DD.MM.YYYY', 'DD.MM.YYYY']
    } else if (dateType === 'month') {
      return ['MM.YYYY', 'MM.YYYY']
    } else if (dateType === 'year') {
      return ['YYYY', 'YYYY']
    }
  }

  // date change
  const dateChange = (date: any) => {
    setRangePickerValue([date[0], date[1]])
  }

  // date range picker
  const dateRangePicker = (type: 'date' | 'month' | 'year') => {
    setDateType(type)
    if (type === 'date') {
      setRangePickerValue([dayjs().startOf('month'), dayjs()])
    } else if (type === 'month') {
      setRangePickerValue([dayjs().startOf('year'), dayjs()])
    } else if (type === 'year') {
      setRangePickerValue([dayjs(), dayjs()])
    }
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const determineTime = () => {
    return (
      <div className="datemineTime">
        <p
          className={`${dateType === 'date' ? 'active' : ''} cur_po`}
          onClick={() => dateRangePicker('date')}
        >
          Kunlik
        </p>
        <p
          className={`${dateType === 'month' ? 'active' : ''} cur_po`}
          onClick={() => dateRangePicker('month')}
        >
          Oylik
        </p>
        <p
          className={`${dateType === 'year' ? 'active' : ''} cur_po`}
          onClick={() => dateRangePicker('year')}
        >
          Yillik
        </p>
        <Space>
          <RangePicker
            format={rangePickerFormat()}
            picker={dateType}
            onChange={dateChange}
            value={rangePickerValue}
          />
        </Space>
      </div>
    )
  }

  // const barChartWidth = () => {
  //   if (data?.data) {
  //     if (data?.data?.length * 70 > 750) {
  //       return data?.data?.length * 30;
  //     } else return 750;
  //   } else return 750;
  // };
  const tickYFunction = (payment: number) => {
    return payment.toLocaleString()
  }
  const tickXFunction = (date: string) => {
    if (dateType === 'date') {
      return dayjs(date)?.format('DD.MM.YYYY')
    } else if (dateType === 'month') {
      return dayjs(date)?.format('MM.YYYY')
    } else if (dateType === 'year') {
      return date
    }
    return dayjs(date)?.format('DD.MM.YYYY')
  }

  return (
    <div className="analyticsCenter">
      <div className="analyticsCenter_main">
        <Tabs tabBarExtraContent={determineTime()}>
          <TabPane tab={'Savdo'} key="1">
            <Spin spinning={paymentLoading}>
              <div className="generalInformation">
                <div className="statistics">
                  <h3>
                    Savdo o'zgarishi &nbsp;
                    <span>
                      <Spin indicator={antIcon} spinning={paymentFetching} />
                    </span>
                  </h3>
                  {/* <ResponsiveContainer width="100%" height="100%"> */}
                  <ResponsiveContainer width={'100%'} height="90%">
                    <BarChart
                      // width={barChartWidth()}
                      width={1000}
                      height={254}
                      data={data?.data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={50}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <XAxis
                        tickFormatter={tickXFunction}
                        dataKey="date"
                        scale={'point'}
                        padding={{ left: 18, right: 10 }}
                      />
                      <YAxis tickFormatter={tickYFunction} />
                      <Tooltip
                        active={true}
                        content={(toolTip: any) => {
                          return (
                            <div
                              style={{
                                backgroundColor: 'white',
                                padding: '0.5rem',
                                border: '1px solid rgb(187, 184, 184)',
                              }}
                            >
                              {toolTip?.payload && dateType === 'date' ? (
                                <span>
                                  {dayjs(toolTip?.payload[0]?.payload?.date)?.format(
                                    'DD.MM.YYYY'
                                  )}
                                </span>
                              ) : null}
                              {toolTip?.payload && dateType === 'month' ? (
                                <span>
                                  {dayjs(toolTip?.payload[0]?.payload?.date)?.format(
                                    'MM.YYYY'
                                  )}
                                </span>
                              ) : null}
                              {toolTip?.payload && dateType === 'year' ? (
                                <span>{toolTip?.payload[0]?.payload?.date}</span>
                              ) : null}
                              <br />
                              {toolTip?.payload ? (
                                <>
                                  <span style={{ color: '#82ca9d' }}>
                                    Keshbek:&nbsp;
                                    {toolTip?.payload[0]?.payload?.cashback?.toLocaleString() ||
                                      0}
                                    &nbsp;so'm
                                  </span>{' '}
                                  <br />
                                  <span style={{ color: '#1890FF' }}>
                                    To'lov:&nbsp;
                                    {toolTip?.payload[1]?.payload?.amount?.toLocaleString() ||
                                      0}
                                    &nbsp;so'm
                                  </span>
                                </>
                              ) : null}
                            </div>
                          )
                        }}
                      />
                      <Bar
                        dataKey="amount"
                        stackId="a"
                        fill="#1890FF"
                        background={{ fill: 'rgba(0, 0, 0, 0.05)' }}
                      />
                      <Bar dataKey="cashback" fill="#82ca9d" stackId="a" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="topClient">
                  <h3>Top mijozlar (cashback sarflash bo'yicha)</h3>
                  {data?.clients?.map((item, index) => {
                    return (
                      <div className="df jc_sb" key={index}>
                        <p>{item?.name}</p>
                        <p>{Number(item?.amount)?.toLocaleString()} so'm</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Spin>
          </TabPane>

          {/* VISIT? */}
          <TabPane tab={'Tashriflar'} key="2">
            <Spin spinning={visitLoading}>
              <div className="generalInformation">
                <div className="statistics">
                  <h3>
                    Tashriflar o'zgarishi &nbsp;
                    <span>
                      <Spin indicator={antIcon} spinning={visitFetching} />
                    </span>
                  </h3>
                  <ResponsiveContainer width={'100%'} height="90%">
                    <BarChart
                      // width={barChartWidth()}
                      width={1000}
                      height={254}
                      data={dataVisit?.data}
                      margin={{
                        top: 5,

                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={50}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <XAxis
                        dataKey="date"
                        tickFormatter={tickXFunction}
                        scale={'point'}
                        padding={{ left: 18, right: 10 }}
                      />
                      <YAxis tickFormatter={tickYFunction} />
                      <Tooltip
                        active={true}
                        content={(toolTip: any) => {
                          return (
                            <div
                              style={{
                                backgroundColor: 'white',
                                padding: '0.5rem',
                                border: '1px solid rgb(187, 184, 184)',
                              }}
                            >
                              {toolTip?.payload && dateType === 'date' ? (
                                <span>
                                  {dayjs(toolTip?.payload[0]?.payload?.date)?.format(
                                    'DD.MM.YYYY'
                                  )}
                                </span>
                              ) : null}
                              {toolTip?.payload && dateType === 'month' ? (
                                <span>
                                  {dayjs(toolTip?.payload[0]?.payload?.date)?.format(
                                    'MM.YYYY'
                                  )}
                                </span>
                              ) : null}
                              {toolTip?.payload && dateType === 'year' ? (
                                <span>{toolTip?.payload[0]?.payload?.date}</span>
                              ) : null}
                              <br />
                              {toolTip?.payload ? (
                                <span>
                                  To'lovlar:&nbsp;
                                  {toolTip?.payload[0]?.payload?.payment?.toLocaleString()}{' '}
                                  ta
                                </span>
                              ) : null}
                            </div>
                          )
                        }}
                      />
                      <Bar
                        dataKey="payment"
                        fill="#1890FF"
                        background={{ fill: 'rgba(0, 0, 0, 0.05)' }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="topClient">
                  <h3>Top mijozlar (cashback sarflash bo'yicha)</h3>
                  {dataVisit?.clients?.map((item, index) => {
                    return (
                      <div className="df jc_sb" key={index}>
                        <p>{item?.name}</p>
                        <p>{Number(item?.amount)?.toLocaleString()} so'm</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Spin>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default AnalyticsCenter
