import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LocalStorage } from '../../services/LocalStorage'
import { Langs } from '../constants/Langs'
import { LANG_KEY } from '../constants/localStorageKeys'
import ru from './ru.json'
import uz from './uz.json'

const resources = {
  uz: {
    translation: uz,
  },
  ru: {
    translation: ru,
  },
}

const defaultLang = LocalStorage.get<string>(LANG_KEY) || Langs.UZ

i18n.use(initReactI18next).init({
  resources,

  lng: defaultLang,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
