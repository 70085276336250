import React from "react";
import AnalyticsBottom from "../../components/analytics/AnalyticsBottom";
import AnalyticsCenter from "../../components/analytics/AnalyticsCenter";
import AnalyticsTop from "../../components/analytics/AnalyticsTop";
// import { useAnalyticsDiscVisit } from "../../queries/queries";
import "./Analytics.scss";

const Analytics = () => {
  // const { data } = useAnalyticsDiscVisit();

  return (
    <div className="analytics">
      <AnalyticsTop />
      <AnalyticsCenter />
      <AnalyticsBottom />
    </div>
  );
};

export default Analytics;
