import React from "react";

function ArrowTop() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74296 5.41958L5.25312 0.21333C5.1246 0.0643066 4.87714 0.0643066 4.74726 0.21333L0.257415 5.41958C0.090618 5.61372 0.241009 5.8981 0.510345 5.8981H9.49003C9.75937 5.8981 9.90976 5.61372 9.74296 5.41958Z"
        fill="#52C41A"
      />
    </svg>
  );
}

export default ArrowTop;
