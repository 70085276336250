export const mainPaths = {
  companies: '/companies',
  detailed_company: '/companies/:id',
  commerce: '/commerce',
  analytics: '/analytics',
  settings: '/settings',
  clients: '/clients',
  payments: '/payments',
  sms: '/sms',
  profile: '/profile',
}
