import { LoadingOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCompanyAnalytics } from '../../queries/queries'
import { queryNames } from '../../queries/queryNames'

const CompanyAnalytics: React.FC = () => {
  const params = useParams<{ id: string }>()
  const qc = useQueryClient()
  const { data, isLoading, isFetching } = useCompanyAnalytics(Number(params.id))
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />

  // invalidate
  const invalidate = () => {
    qc.invalidateQueries(queryNames.COMPANY_ANALYTICS)
  }

  return (
    <Spin spinning={isLoading}>
      <div className="white_box analytics_company">
        <div className="df jc_sb ai_c">
          <h2 className="mb0">Payments</h2>
          <Button type="primary" onClick={invalidate}>
            {isLoading || isFetching ? (
              <Spin indicator={antIcon} />
            ) : (
              <RedoOutlined />
            )}
          </Button>
        </div>
        <ResponsiveContainer width="97%" height="100%">
          <BarChart data={data} barSize={25}>
            <XAxis
              dataKey="date"
              scale={'point'}
              padding={{ left: 12, right: 30 }}
            />
            <YAxis />
            <Tooltip
              active={true}
              content={(toolTip: any) => {
                return (
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '0.5rem',
                      border: '1px solid rgb(187, 184, 184)',
                    }}
                  >
                    {toolTip?.payload ? (
                      <span>{toolTip?.payload[0]?.payload?.date}</span>
                    ) : null}
                    <br />
                    {toolTip?.payload ? (
                      <>
                        <span style={{ color: '#82ca9d' }}>
                          Payments:&nbsp;
                          {toolTip?.payload[0]?.payload?.count || 0}
                          &nbsp;
                        </span>
                      </>
                    ) : null}
                  </div>
                )
              }}
            />
            <Bar
              dataKey="count"
              stackId="a"
              fill="#1890FF"
              background={{ fill: 'rgba(0, 0, 0, 0.05)' }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Spin>
  )
}

export default CompanyAnalytics
