import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSaveSmsTemplate } from '../../queries/mutations'
import { useSmsKeys, useSmsTemplate } from '../../queries/queries'

const SettingsSms: React.FC = () => {
  const { data: smsKeys, isLoading: isLoadingKeys } = useSmsKeys()
  const { data, isLoading } = useSmsTemplate()
  const saveSmsMutation = useSaveSmsTemplate()
  const [key, setKey] = useState<string>()
  const [sms, setSms] = useState('')
  const smsRef = React.useRef<HTMLTextAreaElement>(null)

  // initial value
  useEffect(() => {
    if (data) {
      setSms(data.message)
    }
  }, [data])

  // handle key
  const handleKey = (value: string) => {
    setKey(value)
    setSms((prev) => prev + ` ${value} `)
    smsRef.current?.focus()
  }

  // handle sms
  const handleSms = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSms(e.target.value)
  }

  // save sms
  const saveSms = () => {
    saveSmsMutation.mutate(sms!)
  }

  return (
    <div className="white_box sms">
      <div className="sms_cont">
        <div className="sms_top">
          <div className="sms_left">
            <div>
              {isLoadingKeys || isLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
                />
              ) : null}
              &nbsp; Xabar matni:
            </div>
            <Select placeholder="O'zgaruvchi" value={key} onChange={handleKey}>
              {smsKeys?.map((key: string, index: number) => (
                <Select.Option value={key} key={index}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="sms_right">
            <Input.TextArea
              ref={smsRef}
              autoSize={{ minRows: 6 }}
              value={sms}
              onChange={handleSms}
            />
          </div>
        </div>
        <div className="sms_bottom">
          <div className="df jc_fe">
            <Button
              type="primary"
              onClick={saveSms}
              disabled={!sms}
              loading={saveSmsMutation.isLoading}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsSms
