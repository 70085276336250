import { FormInstance, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import $api from '../hooks/useAxiosInstance'
import { LocalStorage } from '../services/LocalStorage'
import {
  companyEndpoints,
  smsEndpoints,
  staffEndpoints,
  urlsWithoutEndpoints,
} from '../utils/constants/api'
import { USER_KEY } from '../utils/constants/localStorageKeys'
import { ClientPayloadData } from '../utils/models/clients-page/Client'
import { LoginData } from '../utils/models/login-page/Login'
import { StaffsPayloadData } from '../utils/models/settings-page/Staffs'
import { queryNames } from './queryNames'
import { SettingsMainData } from '../utils/models/settings-page/SettingsMain'
import { MakePaymentPayloadData } from '../utils/models/commerce-page/Commerce'
import {
  CreateCompanyData,
  DetailedCompanyData,
} from '../utils/models/companies-page/Companies'

// update company
export function useUpdateCompany(setChanging: (changing: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: DetailedCompanyData) => {
      const res = await $api.post(companyEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: () => {
        message.success('Company updated successfully!')
        qc.invalidateQueries(queryNames.DETAILED_COMPANY)
        setChanging(false)
      },
    }
  )
}

// create company
export function useCreateCompany(setVisible: (visible: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: CreateCompanyData) => {
      const res = await $api.post(companyEndpoints.CREATE, data)
      return res.data
    },
    {
      onSuccess: () => {
        message.success('Company created successfully!')
        qc.invalidateQueries(queryNames.COMPANIES)
        setVisible(false)
      },
    }
  )
}

export function useLogin(useForm: FormInstance<any> | undefined) {
  const url = urlsWithoutEndpoints.login
  return useMutation(
    async (data: LoginData) => {
      const res = await $api.post(url, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        LocalStorage.set(USER_KEY, {
          token: data.Authorization,
          name: data.user.name,
          role: data.user.role,
        })
      },
      onError: () => {
        useForm?.resetFields()
        LocalStorage.remove(USER_KEY)
      },
    }
  )
}

// clients page
export function useCreateUpdateClient(setVisible?: (visible: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: ClientPayloadData) => {
      const res = await $api.post(urlsWithoutEndpoints.client, data)
      return res.data
    },
    {
      onSuccess: (_, data) => {
        qc.invalidateQueries(queryNames.CLIENTS)
        qc.invalidateQueries(queryNames.CLIENT_WITH_BARCODE)
        if (setVisible) setVisible(false)
        if (data.id) {
          message.success('Mijoz yangilandi!')
        } else {
          message.success('Mijoz yaratildi!')
        }
      },
    }
  )
}

export function useDeleteClient() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${urlsWithoutEndpoints.client}/${id}`)
      return res.data
    },
    {
      onSuccess: () => {
        message.success('deleted')
        qc.invalidateQueries(queryNames.CLIENTS)
      },
    }
  )
}

// settings page
export function useDeleteStaff() {
  const { t } = useTranslation()
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(staffEndpoints.INDEX + `/${id}`)
      return res.data
    },
    {
      onSuccess: () => {
        message.success(t('settings.success.deleted'))
        qc.invalidateQueries(queryNames.STAFFS)
      },
    }
  )
}

export function useCreateUpdateStaff(
  setVisible: (visible: boolean) => void,
  form: FormInstance
) {
  const qc = useQueryClient()
  const { t } = useTranslation()
  const url = urlsWithoutEndpoints.createAndUpdateStaff
  return useMutation(
    async (data: StaffsPayloadData) => {
      const res = await $api.post(url, data)
      return res.data
    },
    {
      onSuccess: (_, data) => {
        qc.invalidateQueries(queryNames.STAFFS)
        setVisible(false)
        if (data.id) {
          message.success(t('settings.success.updated'))
        } else {
          message.success(t('settings.success.created'))
        }
        form.resetFields()
      },
    }
  )
}

// make payment
export function useMakePayment(
  setBarCode: (barcode: string) => void,
  setAmount: (amount: string) => void,
  setCashback: (cashback: string) => void,
  setPayingWithCashBack: (payingWithCashBack: boolean) => void
) {
  let url = urlsWithoutEndpoints.payment
  const qc = useQueryClient()
  return useMutation(
    async (data: MakePaymentPayloadData) => {
      const res = await $api.post(url, data)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.PAYMENTS)
        setAmount('')
        setCashback('')
        setBarCode('')
        setPayingWithCashBack(false)
        document.getElementById('barcode_input')?.focus()
      },
    }
  )
}

// delete payment
export function useDeletePayment() {
  const qc = useQueryClient()
  let url = urlsWithoutEndpoints.payment
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(url + `/${id}`)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.PAYMENTS)
        qc.invalidateQueries(queryNames.CLIENT_WITH_BARCODE)
      },
    }
  )
}

// settings Main
export function useUpdateSettingsMain() {
  const qc = useQueryClient()
  const url = urlsWithoutEndpoints.mainSettings
  return useMutation(
    async (data: SettingsMainData) => {
      const res = await $api.post(url, data)
      return res.data
    },

    {
      onSuccess: () => {
        message.success('Muvaffaqiyatli yangilandi')
        qc.invalidateQueries([queryNames.SETTINGS_MAIN])
      },
    }
  )
}

// save sms template
export function useSaveSmsTemplate() {
  const qc = useQueryClient()
  return useMutation(
    async (message: string) => {
      const res = await $api.post(smsEndpoints.INDEX, { message })
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.SMS_TEMPLATE)
        message.success('SMS matni yangilandi!')
      },
    }
  )
}

// send special sms
export function useSendSpecialSms() {
  return useMutation(
    async (message: string) => {
      const res = await $api.post(smsEndpoints.SPECIAL, { message })
      return res.data
    },
    {
      onSuccess: () => {
        message.success('SMS yuborildi!')
      },
    }
  )
}

// update profile
export function useUpdateProfile() {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      name: string
      login: string
      password?: string
      reset_password?: string
    }) => {
      const res = await $api.post(urlsWithoutEndpoints.profile, data)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Ma'lumotlaringiz muvaffaqiyatli yangilandi")
        qc.invalidateQueries(queryNames.PROFILE)
      },
    }
  )
}
