import React from 'react'

const BarcodeIcon: React.FC = () => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8828 0.398438C29.1656 0.458202 23.7469 1.7332 17.85 3.20742C5.79726 6.19571 5.97656 6.13594 5.97656 7.78946C5.97656 8.76562 7.66992 18.1488 8.00859 18.9855C8.26758 19.6629 9.10429 20.1012 12.0527 21.157C17.9496 23.2688 27.1934 25.3207 31.0383 25.3805L32.6719 25.4004L32.7914 26.0379C32.9707 26.9742 32.7516 27.9504 31.8551 30.0223C30.8391 32.4129 30.859 32.9906 31.9148 33.8273C32.4527 34.2457 35.0426 34.8633 36.2977 34.8633C37.4332 34.8633 37.473 34.8434 38.0707 34.0266C39.007 32.7516 39.8039 30.9387 40.302 28.9664L40.7402 27.1934L40.8996 28.3887C41.2184 30.7992 40.4613 34.0266 39.2262 35.5207C38.3895 36.5367 37.9312 36.6762 36.4371 36.4371C35.7598 36.3375 35.182 36.2777 35.1223 36.3375C35.0824 36.3773 35.1422 37.1344 35.2617 38.0309C35.4609 39.4852 35.441 39.7242 35.0824 40.5211C34.8234 41.1188 34.425 41.577 33.8871 41.9156C32.0941 43.0512 32.0145 44.3461 33.5883 46.9957C34.8234 49.0875 35.7 50.1234 36.6762 50.6016C37.3535 50.9602 37.8516 51 41.4973 51C45.4219 51 45.5613 50.9801 46.5574 50.502C48.191 49.6852 49.7848 47.2746 50.5019 44.5254C50.8805 43.0512 50.8805 39.784 50.5219 38.0508C50.3625 37.3336 49.6055 34.5645 48.8086 31.8949C46.8762 25.3605 46.4379 23.2887 46.2785 20.3801C46.0992 16.6348 46.6172 14.0051 47.8723 12.5707C48.9082 11.3555 49.0078 10.8973 49.0078 6.97266C49.0078 4.86094 48.9281 3.20742 48.8086 2.96836C48.3105 2.07188 46.4777 1.61367 40.8398 0.996094C38.3098 0.71719 32.0742 0.199219 31.5762 0.239063C31.357 0.239063 30.6 0.318752 29.8828 0.398438ZM19.5234 12.0727C23.966 12.9094 27.6316 13.6266 27.6516 13.6465C27.791 13.7859 28.8867 20.4398 28.8469 20.8383C28.7871 21.2566 28.7871 21.2566 25.4004 20.6191C19.2645 19.4438 11.8734 17.2324 11.5348 16.4555C11.3953 16.0969 10.3594 10.8773 10.3594 10.4789C10.3594 10.3992 10.5984 10.3793 10.9172 10.4391C11.216 10.4988 15.0809 11.2359 19.5234 12.0727Z"
        fill="#1890FF"
      />
      <path
        d="M0 40.1426V47.0156H1.99219H3.98438V40.1426V33.2695H1.99219H0V40.1426Z"
        fill="#1890FF"
      />
      <path
        d="M5.97656 40.1426V47.0156H6.87305H7.76953V40.1426V33.2695H6.87305H5.97656V40.1426Z"
        fill="#1890FF"
      />
      <path
        d="M9.76172 40.1426V47.0156H11.7539H13.7461V40.1426V33.2695H11.7539H9.76172V40.1426Z"
        fill="#1890FF"
      />
      <path
        d="M15.7383 40.1426V47.0156H16.7344H17.7305V40.1426V33.2695H16.7344H15.7383V40.1426Z"
        fill="#1890FF"
      />
    </svg>
  )
}

export default BarcodeIcon
