import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import CashbackIcon from '../../assets/svg/CashbackIcon'
import { useMakePayment } from '../../queries/mutations'
import { useMainSettings } from '../../queries/queries'

interface IProps {
  barcode: string
  clientBalance: number
  setBarcode: (barcode: string) => void
  availablePaying: boolean
}

const Paying: React.FC<IProps> = ({
  barcode,
  clientBalance,
  setBarcode,
  availablePaying,
}) => {
  const [amount, setAmount] = useState('')
  const [cashback, setCashback] = useState('')
  const [payingWithCashback, setPayingWithCashback] = useState(false)
  const { data } = useMainSettings()
  const makePaymentMutation = useMakePayment(
    setBarcode,
    setAmount,
    setCashback,
    setPayingWithCashback
  )
  const cashback_ref = React.useRef<HTMLInputElement>(null)
  const amount_ref = React.useRef<HTMLInputElement>(null)
  const totalPrice = Number(amount) + Number(cashback)

  // change amount
  const handleChangeAmount = (value: string | undefined) => {
    if (value) {
      if (value === '0') {
        setAmount('')
      } else {
        setAmount(value)
      }
    } else {
      setAmount('')
    }
  }

  // handle cashback
  const handleChangeCashback = (value: string | undefined) => {
    if (value) {
      if (value === '0') {
        setCashback('')
      } else {
        setCashback((prev) => {
          let newAmount: number
          if (Number(prev) > Number(value)) {
            newAmount = Number(prev) - Number(value)
            setAmount((prev) => String(Number(prev) + newAmount))
          } else {
            newAmount = Number(value) - Number(prev)
            setAmount((prev) => {
              if (newAmount <= Number(prev)) {
                return String(Number(prev) - newAmount)
              } else return prev
            })
          }
          return value
        })
      }
    } else {
      setCashback((prev) => {
        setAmount((prevAmount) => String(Number(prevAmount) + Number(prev)))
        return ''
      })
    }
  }

  // clear cashback
  const clearCashback = () => {
    setCashback('')
  }
  useEffect(() => {
    if (!payingWithCashback) {
      clearCashback()
    }
  }, [payingWithCashback])

  // error
  const error = () => {
    if (Number(amount) > Number(data?.amount)) {
      return 'ant-form-item-has-error'
    } else {
      return ''
    }
  }

  // cashback error
  const cashbackError = () => {
    if (Number(cashback) > clientBalance) {
      return 'ant-form-item-has-error'
    } else return ''
  }

  // using cashback
  const usingCashback = async () => {
    if (!payingWithCashback) {
      await setPayingWithCashback(true)
      if (amount !== '') {
        if (Number(amount) >= clientBalance) {
          await setCashback(String(Math.floor(clientBalance / 1000) * 1000))
          const newAmount =
            (await Number(amount)) - Math.floor(clientBalance / 1000) * 1000
          await setAmount(String(newAmount))
        } else {
          setCashback(amount)
          setAmount('')
        }
      }
      cashback_ref.current?.focus()
    } else {
      setAmount(String(Number(amount) + Number(cashback)))
      setPayingWithCashback(false)
      amount_ref.current?.focus()
    }
  }

  // is empty amount
  const isEmptyAmount = () => {
    if (amount !== '' || cashback !== '') {
      return false
    } else {
      return true
    }
  }

  // make payment
  const makePayment = () => {
    const payload = {
      barcode,
      amount: Number(amount),
      cashback: Number(cashback),
    }
    if (
      !error() &&
      !cashbackError() &&
      !isEmptyAmount() &&
      !makePaymentMutation.isLoading
    ) {
      makePaymentMutation.mutate(payload)
    }
  }

  // amount key down
  const amountKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makePayment()
    }

    if (!payingWithCashback && e.key === '/') {
      await setPayingWithCashback(true)
      if (amount !== '') {
        if (Number(amount) >= clientBalance) {
          await setCashback(String(Math.floor(clientBalance / 1000) * 1000))
          const newAmount =
            (await Number(amount)) - Math.floor(clientBalance / 1000) * 1000
          await setAmount(String(newAmount))
        } else {
          setCashback(amount)
          setAmount('')
        }
      }
      cashback_ref.current?.focus()
    }
  }

  // cashback key down
  const cashbackKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makePayment()
    }

    if (e.key === '/') {
      setAmount(String(Number(amount) + Number(cashback)))
      setPayingWithCashback(false)
      amount_ref.current?.focus()
    }
  }

  // disabled paying btn
  const disabledPayingBtn = () => {
    if (!!error() || !!cashbackError() || isEmptyAmount()) {
      return true
    }
    if (amount === '0' && !cashback) {
      return true
    }
    if (!availablePaying) {
      return true
    }
    return false
  }

  return (
    <>
      <p className="title">To'lov</p>
      <div className="payment">
        <div className="payment_input">
          <div className={`${error()} cont`}>
            <CurrencyInput
              allowDecimals={false}
              allowNegativeValue={false}
              step={10000}
              className={`${error() ? '' : 'amount_input '} ant-input`}
              placeholder="To'lov summasini kiriting"
              onValueChange={handleChangeAmount}
              onKeyDown={amountKeyDown}
              value={amount}
              ref={amount_ref}
              id="amount_input"
              autoComplete="off"
            />
            <span
              className={`${
                error() ? 'error' : ''
              } amount ant-form-item-explain-error`}
            >
              Maksimal to'lov summasi {Number(data?.amount).toLocaleString()} so'm!
            </span>
          </div>
          {payingWithCashback && (
            <div className={`${cashbackError()} cont`}>
              <CurrencyInput
                allowDecimals={false}
                allowNegativeValue={false}
                step={10000}
                className={`${cashbackError() ? '' : 'cashback_input '} ant-input`}
                placeholder="Balansdan summa kiriting"
                onValueChange={handleChangeCashback}
                onKeyDown={cashbackKeyDown}
                value={cashback}
                ref={cashback_ref}
                id="cashback_input"
                autoComplete="off"
              />
              <span
                className={`${
                  cashbackError() ? 'error' : ''
                } cashback ant-form-item-explain-error`}
              >
                Balansingiz: {clientBalance.toLocaleString()} so'm!
              </span>
            </div>
          )}
          <Button
            loading={makePaymentMutation.isLoading}
            type="primary"
            onClick={makePayment}
            disabled={disabledPayingBtn()}
          >
            {!disabledPayingBtn() && (
              <span>{totalPrice.toLocaleString()} so'm -</span>
            )}
            <span>To'lash</span>
          </Button>
        </div>
        <Button
          type={payingWithCashback ? 'default' : 'primary'}
          className="cashback_btn"
          onClick={usingCashback}
        >
          <CashbackIcon />
          <span>Balansdan</span>
          <span>to'lash</span>
        </Button>
      </div>
    </>
  )
}

export default Paying
