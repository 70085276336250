import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SettingsMain from '../../components/settings/SettingsMain'
import SettingsSms from '../../components/settings/SettingsSms'
import SettingsStaffs from '../../components/settings/SettingsStaffs'

import './Settings.scss'

const Settings: React.FC = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()

  return (
    <Tabs defaultActiveKey="1" className="settings">
      <TabPane tab={t('Asosiy')} key="1">
        <SettingsMain />
      </TabPane>
      <TabPane tab={t('Xodimlar')} key="2">
        <SettingsStaffs />
      </TabPane>
      <TabPane tab="SMS" key="3">
        <SettingsSms />
      </TabPane>
    </Tabs>
  )
}

export default Settings
