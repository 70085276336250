import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RootRouter from './routing/RootRouter'
import './utils/lang/i18n'
import { QueryClient, QueryClientProvider } from 'react-query'

import 'antd/dist/antd.css'
import './App.scss'

const App = () => {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
