import { Button, Form, Input } from 'antd'
import React from 'react'
import { useSendSpecialSms } from '../../queries/mutations'

const SendSMS: React.FC = () => {
  const { Item } = Form
  const specialSMS = useSendSpecialSms()

  // on finish
  const onFinish = (values: { message: string }) => {
    specialSMS.mutate(values.message)
  }

  return (
    <div className="white_box sms_send">
      <p className="title">Barcha mijozlarga sms yuborish</p>
      <Form layout="vertical" onFinish={onFinish}>
        <Item
          name="message"
          rules={[{ required: true, message: 'Xabar matnini kiriting!' }]}
          label="Xabar matni"
        >
          <Input.TextArea className="sms_area" autoSize={{ minRows: 6 }} />
        </Item>
        <div className="df jc_fe">
          <Button type="primary" htmlType="submit" loading={specialSMS.isLoading}>
            Yuborish
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default SendSMS
