import { LoadingOutlined } from '@ant-design/icons'
import { Input, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import BarcodeIcon from '../../assets/svg/BarcodeIcon'
import { useClientWithBarcode } from '../../queries/queries'
import ClientWithBarcode from './ClientWithBarcode'

interface IProps {
  barcode: string
  setBarcode: any
  setClientBalance: (balance: number) => void
  setAvailablePaying: (availablePaying: boolean) => void
}

const CommerceLeft: React.FC<IProps> = ({
  barcode,
  setBarcode,
  setClientBalance,
  setAvailablePaying,
}) => {
  const [clientForm, setClientForm] = useState(false)
  const { data, isLoading, isFetching } = useClientWithBarcode(
    barcode,
    setClientBalance,
    setAvailablePaying,
    setClientForm,
    setBarcode
  )

  useEffect(() => {
    document.getElementById('barcode_input')?.focus()
    if (!data) {
      setClientForm(false)
    }
  }, [barcode.length, data])

  // handle barcode
  const handleBarcode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode((prev: string) => {
      if (e.target.value.length <= 13) {
        return e.target.value
      } else {
        if (e.target.value.length === 26) {
          return e.target.value.slice(-13)
        }
        return prev
      }
    })
  }

  return (
    <div className="commerce_left">
      <div className="commerce_left_top">
        <div className="title">
          Mijoz izlash&nbsp;
          {isLoading || isFetching ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
          ) : null}
        </div>
        <div className="barcode">
          <Input
            type="number"
            onChange={handleBarcode}
            placeholder="barcode"
            size="large"
            value={barcode}
            id="barcode_input"
          />
          <BarcodeIcon />
        </div>
      </div>
      {clientForm && (
        <div className="commerce_left_bottom">
          <ClientWithBarcode data={data} barcode={barcode} />
        </div>
      )}
    </div>
  )
}

export default CommerceLeft
