import React, { useState } from 'react'
import CommerceLeft from '../../components/commerce/CommerceLeft'
import CommerceRight from '../../components/commerce/CommerceRight'

import './Commerce.scss'

const Commerce: React.FC = () => {
  const [barcode, setBarcode] = useState('')
  const [clientBalance, setClientBalance] = useState<number>(0)
  const [availablePaying, setAvailablePaying] = useState<boolean>(false)

  window.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      document.getElementById('barcode_input')?.focus()
    }
  })
  document
    .getElementById('barcode_input')
    ?.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setBarcode('')
      }
    })

  return (
    <div className="commerce">
      <CommerceLeft
        barcode={barcode}
        setBarcode={setBarcode}
        setClientBalance={setClientBalance}
        setAvailablePaying={setAvailablePaying}
      />
      <CommerceRight
        barcode={barcode}
        setBarcode={setBarcode}
        clientBalance={clientBalance}
        availablePaying={availablePaying}
      />
    </div>
  )
}

export default Commerce
