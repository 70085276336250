import { Radio, RadioChangeEvent, Spin, Table } from 'antd'
import React, { useState } from 'react'
import { Area, AreaChart, Cell, Pie, PieChart, Tooltip } from 'recharts'
import ArrowBottom from '../../assets/svg/ArrowBottom'

import ArrowTop from '../../assets/svg/ArrowTop'
import {
  useBestAccountant,
  useLastPayment,
  useLastPaymentTable,
} from '../../queries/queries'

const AnalyticsBottom = () => {
  const { data, isLoading: cashbackLoading } = useLastPayment()
  const [page, setPage] = useState(1)
  const { data: lastPaymentTable, isLoading: tableLoading } =
    useLastPaymentTable(page)
  const [dateChange, setDateChange] = useState<'all' | 'month' | 'year'>('all')
  const { data: dataBestAccountant, isLoading: accountantLoading } =
    useBestAccountant(dateChange)
  const COLORS = [
    '#96d7ff',
    '#0aff99',
    '#ff8700',
    '#ffcfd2',
    '#ff0000',
    '#deff0a',
    '#0aefff',
    '#147df5',
    '#007ea7',
  ]

  const bestAccouuntCashbackall = () => {
    if (dataBestAccountant) {
      let cashbackall = 0
      for (let i = 0; i < dataBestAccountant.length; i++) {
        cashbackall += dataBestAccountant[i].cashback
      }
      return cashbackall
    } else {
      return 1
    }
  }

  const chartWidth = () => {
    return document.querySelector('.collectStatistics')?.clientWidth
  }

  const handleDateChange = (e: RadioChangeEvent) => {
    setDateChange(e?.target?.value)
  }
  return (
    <div className="analyticsBottom">
      <div className="left">
        <div className="title">
          <h3>Cashback jamg'arish va sarflash ko'rsatkichi</h3>
        </div>
        <Spin spinning={cashbackLoading}>
          <div className="collectSpend">
            <div className="collect universalClass">
              <p>Jamg'arish (bugun) </p>
              <h4>
                {data?.collect_cashback?.toLocaleString() || 0}so'm
                <span>
                  {Math.abs(data?.collect_percent || 0)}%
                  {data?.collect_percent! > 0 ? <ArrowTop /> : <ArrowBottom />}
                </span>
              </h4>
              <div className="collectStatistics">
                {/* <ResponsiveContainer width="100%" height="100%"> */}
                <AreaChart
                  width={chartWidth()}
                  height={60}
                  data={data?.collect_data}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <Area
                    type="monotone"
                    dataKey="discount"
                    stroke="#1089fe"
                    fill="#cfe8fe"
                  />
                </AreaChart>
                {/* </ResponsiveContainer> */}
              </div>
            </div>
            <div className="spend universalClass">
              <p>Sarflash (bugun) </p>
              <h4>
                {data?.spend_cashback?.toLocaleString() || 0}so'm
                <span>
                  {Math.abs(data?.spend_percent || 0)}%
                  {data?.collect_percent! > 0 ? <ArrowTop /> : <ArrowBottom />}
                </span>
              </h4>
              <div className="collectStatistics">
                {/* <ResponsiveContainer width="100%" height="100%"> */}
                <AreaChart
                  width={chartWidth()}
                  height={60}
                  data={data?.spend_data}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <Area
                    type="monotone"
                    dataKey="discount"
                    stroke="#1089fe"
                    fill="#cfe8fe"
                  />
                </AreaChart>
                {/* </ResponsiveContainer> */}
              </div>
            </div>
          </div>
        </Spin>
        <div className="collectSpendTable">
          <Table
            pagination={{
              pageSize: 5,
              total: lastPaymentTable?.total,
              onChange: (page: number) => {
                setPage(page)
              },
              current: lastPaymentTable?.current_page,
              hideOnSinglePage: true,
              showSizeChanger: false,
              style: {
                marginRight: '1rem',
              },
            }}
            dataSource={lastPaymentTable?.data}
            rowKey={(record) => {
              return record.id
            }}
            loading={tableLoading}
          >
            <Table.Column
              render={(_, __, index) => {
                return <span>{index + 1}</span>
              }}
              title={'№'}
              align="center"
            />
            <Table.Column dataIndex={'car_number'} title={'Mijoz'} align="center" />
            <Table.Column
              render={(record) => (
                <span>{record.collect.toLocaleString()} so'm</span>
              )}
              title={"Jamg'arish"}
              align="right"
            />
            <Table.Column
              render={(record) => (
                <span>{record.cashback.toLocaleString()} so'm</span>
              )}
              title={'Sarflash'}
              align="right"
            />
          </Table>
        </div>
      </div>
      <div className="right">
        <div className="title">
          <h3>Top kassirlar</h3>
          <Radio.Group value={dateChange} onChange={handleDateChange}>
            <Radio.Button value={'all'}>Jami</Radio.Button>
            <Radio.Button value={'month'}>Oylik</Radio.Button>
            <Radio.Button value={'year'}>Yillik</Radio.Button>
          </Radio.Group>
        </div>
        <div className="chartContainer">
          <h5>Cashback sarflash bo'yicha</h5>
          <Spin spinning={accountantLoading}>
            <div className="chartTopAll">
              <div className="chart">
                <PieChart style={{ overflow: 'hidden' }} width={310} height={250}>
                  <Pie
                    data={dataBestAccountant}
                    cx={140}
                    cy={120}
                    innerRadius={80}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="cashback"
                  >
                    {dataBestAccountant?.map((entry: any, index: number) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    content={(tooltip: any) => {
                      return (
                        <div
                          style={{
                            backgroundColor: 'white',
                            padding: '0.5rem',
                            border: '1px solid rgb(187, 184, 184)',
                          }}
                        >
                          {tooltip?.payload ? (
                            <span>{tooltip?.payload[0]?.name}:</span>
                          ) : null}
                          {tooltip?.payload ? (
                            <span>
                              {' '}
                              {tooltip?.payload[0]?.value?.toLocaleString()} so'm
                            </span>
                          ) : null}
                        </div>
                      )
                    }}
                  />
                  <text x={140} y={130} textAnchor="middle">
                    {bestAccouuntCashbackall().toLocaleString()} so'm
                  </text>
                </PieChart>
              </div>
              <div className="bestAccouunt">
                {dataBestAccountant?.map((item, index) => {
                  return (
                    <div className="bestAccouunt_data" key={index}>
                      <p>
                        <span
                          style={{
                            backgroundColor: `${COLORS[index % COLORS.length]}`,
                            width: '6px',
                            display: 'inline-block',
                            height: '6px',
                            borderRadius: '50%',
                            marginRight: '0.5rem',
                            marginBottom: '0',
                          }}
                        />
                        {item.name}
                      </p>
                      <p>
                        <span
                          style={{
                            marginRight: '0.5rem',
                            color: ' rgba(0, 0, 0, 0.45)',
                          }}
                        >
                          {Math.round(
                            (item.cashback * 100) / bestAccouuntCashbackall()
                          ) || 0}
                          %
                        </span>
                        {item.cashback.toLocaleString()} so'm
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsBottom
