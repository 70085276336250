import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthService } from '../services/AuthService'
import { mainPaths } from '../utils/routes/main/mainPaths'
import { rootPaths } from '../utils/routes/root/rootPaths'

export const Redirector: React.FC = () => {
  let page
  const token = AuthService.getToken()
  const { pathname } = useLocation()
  if (token) {
    if (!AuthService.isDeveloper()) {
      page = rootPaths.main + mainPaths.commerce
    } else {
      page = rootPaths.main + mainPaths.companies
    }
  } else {
    if (pathname !== rootPaths.login) {
      page = rootPaths.login
    } else return null
  }
  return <Navigate to={page} />
}
