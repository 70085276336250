import { Navigate, Outlet } from 'react-router-dom'
import { AuthService } from '../../../services/AuthService'
import { rootPaths } from '../root/rootPaths'
import { mainPaths } from './mainPaths'

const BossRoutes = () => {
  return AuthService.isBoss() ? (
    <Outlet />
  ) : (
    <Navigate to={rootPaths.main + mainPaths.commerce} />
  )
}

export default BossRoutes
