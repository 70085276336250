import React from 'react'

const ExitIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 3.11111L9.92 4.2L7.84 6.22222H16V7.77778H7.84L9.92 9.8L8.8 10.8889L4.8 7L8.8 3.11111ZM1.6 12.4444H8V14H1.6C0.719999 14 0 13.3 0 12.4444V1.55556C0 0.7 0.719999 0 1.6 0H8V1.55556H1.6V12.4444Z"
        fill="#FF5151"
      />
    </svg>
  )
}

export default ExitIcon
