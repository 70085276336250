import { Navigate, Outlet } from 'react-router-dom'
import { AuthService } from '../../../services/AuthService'
import { rootPaths } from '../root/rootPaths'
import { mainPaths } from './mainPaths'

const DeveloperRoutes = () => {
  return AuthService.isDeveloper() ? (
    <Outlet />
  ) : (
    <Navigate to={rootPaths.main + mainPaths.commerce} />
  )
}

export default DeveloperRoutes
