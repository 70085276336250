import { UserOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import InfoIcon from '../../assets/svg/InfoIcon'
import { AuthService } from '../../services/AuthService'
import { mainPaths } from '../../utils/routes/main/mainPaths'
import { rootPaths } from '../../utils/routes/root/rootPaths'
import './Topbar.scss'

const Topbar: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const pathname = useLocation().pathname.replace(rootPaths.main, '')

  // title
  const title = () => {
    if (pathname === mainPaths.commerce) {
      return t('Savdo')
    } else if (pathname === mainPaths.analytics) {
      return t('Analitika')
    } else if (pathname === mainPaths.settings) {
      return t('Sozlash')
    } else if (pathname === mainPaths.clients) {
      return t('Mijozlar')
    } else if (pathname === mainPaths.payments) {
      return t("To'lovlar")
    } else if (pathname === mainPaths.sms) {
      return 'SMS'
    } else if (pathname === mainPaths.companies) {
      return 'Companies'
    } else if (pathname === mainPaths.profile) {
      return 'Mening sahifam'
    }
    return 'Error'
  }

  // to profile page
  const toProfilePage = () => {
    if (!AuthService.isDeveloper()) {
      navigate(rootPaths.main + mainPaths.profile)
    }
  }

  // // change lang
  // const changeLang = (value: boolean) => {
  //   if (value) {
  //     i18n.changeLanguage(Langs.RU)
  //     LocalStorage.set(LANG_KEY, Langs.RU)
  //   } else {
  //     i18n.changeLanguage(Langs.UZ)
  //     LocalStorage.set(LANG_KEY, Langs.UZ)
  //   }
  // }

  // // default lang
  // const defaultLang = () => {
  //   if (i18n.language === Langs.RU) {
  //     return true
  //   } else if (i18n.language === Langs.UZ) {
  //     return false
  //   }
  //   return false
  // }

  return (
    <div className="topbar">
      <p className="title">{title()}</p>
      <div className="topbar_right">
        {/* <div className={`${defaultLang()} flag`}>
          <Switch
            defaultChecked={defaultLang()}
            className="langSwitch"
            // checkedChildren={<UzFlag />}
            // unCheckedChildren={<RuFlag />}
            onChange={changeLang}
          />
        </div> */}
        <div className="info">
          <Popover
            trigger="click"
            content={
              <div className="info_cont">
                <div className="esc">
                  <div className="button">Esc</div>
                  <span>- Mijoz izlashga o'tish</span>
                </div>
                <div className="slash">
                  <div className="button">/</div>
                  <span>- Cashback orqali to'lov</span>
                </div>
                <div className="call">
                  <p>
                    +99891 253 26 03
                    <br />
                    <span>Yordam markazi</span>
                  </p>
                </div>
              </div>
            }
          >
            <span className="info_icon">
              <InfoIcon />
            </span>
          </Popover>
        </div>
        <div className="user" onClick={toProfilePage}>
          <Avatar icon={<UserOutlined />} size={32} />
          <p>{AuthService.getUser()?.name || ''}</p>
        </div>
      </div>
    </div>
  )
}

export default Topbar
