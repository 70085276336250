import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Spin, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeletePayment } from '../../queries/mutations'
import { usePayments } from '../../queries/queries'
import { PaymentsData } from '../../utils/models/payments-page/Payments'

interface IProps {
  barcode: string
}

const PaymentsInCommerce: React.FC<IProps> = ({ barcode }) => {
  const [page, setPage] = useState(1)
  const searchText = barcode.length === 13 ? barcode : ''
  const { data, isLoading, isFetching } = usePayments(searchText, page)
  const deleteMutation = useDeletePayment()
  const { t } = useTranslation()

  // delete payment
  const deletePayment = (id: number) => {
    deleteMutation.mutate(id)
  }

  return (
    <div>
      <div className="title">
        To'lovlar tarixi &nbsp;
        {isLoading || isFetching ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        ) : null}
      </div>
      <Table
        loading={isLoading}
        pagination={{
          pageSize: 5,
          total: data?.total,
          onChange: (page: number) => {
            setPage(page)
          },
          current: data?.current_page,
          hideOnSinglePage: true,
          showSizeChanger: false,
          style: {
            marginRight: '1rem',
          },
        }}
        className="tablePayments"
        dataSource={data?.data}
        rowKey={(record) => record.payment_id}
      >
        <Table.Column
          title="Sana"
          render={(record: PaymentsData) =>
            dayjs(record.created_at * 1000).format('DD.MM.YYYY HH:mm')
          }
        />
        <Table.Column dataIndex={'car_number'} title="Mijoz" />
        <Table.Column
          title="Umumiy to'lov"
          render={(record: PaymentsData) => (
            <span>{(record.amount + record.cashback).toLocaleString()} so'm</span>
          )}
        />
        <Table.Column
          title="Mijozdan"
          align="right"
          render={(record: PaymentsData) => (
            <span>{record.amount.toLocaleString()} so'm</span>
          )}
        />
        <Table.Column
          title="Balansdan"
          align="right"
          render={(record: PaymentsData) => (
            <span>{record.cashback.toLocaleString()} so'm</span>
          )}
        />
        <Table.Column
          className="status"
          align="center"
          title="Holat"
          render={(record: PaymentsData) => (
            <Tag color={record.status === 'active' ? 'green' : 'red'}>
              {record.status === 'active' ? 'muvaffaqiyatli' : "o'chirilgan"}
            </Tag>
          )}
        />
        <Table.Column
          align="center"
          render={(record) => {
            if (record.status === 'active') {
              return (
                <Popconfirm
                  title={"To'lovni o'chirmoqchimisiz?"}
                  onConfirm={() => deletePayment(record.payment_id)}
                  okText={t('Ha')}
                  cancelText={t("Yo'q")}
                  placement="left"
                >
                  <DeleteOutlined />
                </Popconfirm>
              )
            } else return null
          }}
        />
      </Table>
    </div>
  )
}

export default PaymentsInCommerce
