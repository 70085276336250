import { USER_KEY } from '../utils/constants/localStorageKeys'
import { rootPaths } from '../utils/routes/root/rootPaths'
import { LocalStorage } from './LocalStorage'

export const AuthService = {
  token: '',

  login(token: string, name: string) {
    LocalStorage.set(USER_KEY, {
      token,
      name,
    })
    this.token = token
  },

  logout() {
    LocalStorage.clear()
    window.location.href = rootPaths.login
  },

  getUser(): { token: string; name: string; role: string } | undefined {
    return LocalStorage.get<{ token: string; name: string; role: string }>(USER_KEY)
  },

  getToken(): string | undefined {
    if (this.token) {
      return this.token
    }
    return LocalStorage.get<{ token: string; name: string }>(USER_KEY)?.token
  },
  isBoss() {
    const role = AuthService.getUser()?.role
    if (role === 'direktor') {
      return true
    } else return false
  },
  isDeveloper() {
    const role = AuthService.getUser()?.role
    if (role === 'developer') {
      return true
    } else return false
  },
}
