import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactInputMask from 'react-input-mask'
import { useCreateUpdateClient } from '../../queries/mutations'
import {
  ClientDataWithCashback,
  ClientPayloadData,
} from '../../utils/models/clients-page/Client'

interface IProps {
  data?: ClientDataWithCashback
  barcode: string
}

interface FormFields extends ClientPayloadData {
  barcode: string
}

const ClientWithBarcode: React.FC<IProps> = ({ data, barcode }) => {
  const { Item } = Form
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [changeable, setChangeable] = useState(false)
  const clientMutation = useCreateUpdateClient()

  // initial values
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        phone_number: data.phone_number,
        car_number: data.car_number,
        name: data.name,
        cashback: `${Number(data.cashback)?.toLocaleString()} so'm`,
      })
    }
  }, [data, form])

  // on finish
  const onFinish = (values: FormFields) => {
    let payload = {
      ...values,
      barcode: barcode,
      cashback: '',
      car_number: values.car_number?.toUpperCase(),
    }
    if (data?.id) {
      payload = { ...payload, id: data.id }
    }
    clientMutation.mutate(payload)
  }

  // input disable
  const inputDisable = () => {
    if (data?.id && !changeable) {
      return true
    } else return false
  }

  // title
  const title = () => {
    if (data?.id) {
      return 'Mavjud mijoz'
    } else {
      return 'Yangi mijoz yaratish'
    }
  }

  return (
    <>
      <p className="title">{title()}</p>
      <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
        <Item
          name="phone_number"
          label={t('clients.phone_number')}
          rules={[
            {
              required: true,
              message: t('clients.errors.phone_number'),
            },
          ]}
        >
          <ReactInputMask mask="+\9\98 99 999 99 99" disabled={inputDisable()}>
            {() => <Input id="client_number_input" disabled={inputDisable()} />}
          </ReactInputMask>
        </Item>
        <Item
          name="car_number"
          label={t('clients.car_number')}
          rules={[
            {
              required: true,
              message: t('clients.errors.car_number'),
            },
          ]}
        >
          <ReactInputMask mask="99 ******" disabled={inputDisable()}>
            {() => <Input disabled={inputDisable()} />}
          </ReactInputMask>
        </Item>
        <Item name="name" label={t('Name')}>
          <Input disabled={inputDisable()} />
        </Item>
        {data?.id && (
          <Item name="cashback" label={'Cashback'}>
            <Input disabled />
          </Item>
        )}
        {data?.id && !changeable ? (
          <div className="df jc_fe">
            <Button onClick={() => setChangeable(true)}>Tahrirlash</Button>
          </div>
        ) : null}
        {!data?.id || changeable ? (
          <div className="df jc_fe">
            <Button
              loading={clientMutation.isLoading}
              type="primary"
              htmlType="submit"
            >
              Saqlash
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  )
}

export default ClientWithBarcode
