import { Button, DatePicker, Form, Input, Popconfirm, Spin, Switch } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUpdateCompany } from '../../queries/mutations'
import { useDetailedCompany } from '../../queries/queries'
import { Status } from '../../utils/constants/Status'
import { CompanyFormFields } from '../../utils/models/companies-page/Companies'

interface IProps {
  setTitle: (title: string) => void
}

const CompanyMain: React.FC<IProps> = ({ setTitle }) => {
  const params = useParams<{ id: string }>()
  const { data, isLoading } = useDetailedCompany(Number(params.id))
  const { Item } = Form
  const [form] = Form.useForm()
  const [changing, setChanging] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>()
  const updateCompany = useUpdateCompany(setChanging)

  // initial values
  useEffect(() => {
    if (data) {
      setTitle(data.name)
      setStatus(data?.status === Status.ACTIVE ? true : false)
      form.setFieldsValue({
        name: data?.name,
        start_time: dayjs(data?.start_time, 'DD.MM.YYYY'),
        end_time: dayjs(data?.end_time, 'DD.MM.YYYY'),
      })
    }
  }, [form, data, setTitle])

  // default status
  const changeStatus = (value: boolean) => {
    setStatus(value)
  }

  // on finish
  const onFinish = (values: CompanyFormFields) => {
    const dataUpdate = {
      id: Number(params.id),
      name: values.name,
      start_time: dayjs(values.start_time).format('DD.MM.YYYY'),
      end_time: dayjs(values.end_time).format('DD.MM.YYYY'),
      status: status ? Status.ACTIVE : Status.PASSIVE,
    }
    updateCompany.mutate(dataUpdate)
  }

  return (
    <div className="white_box main">
      <Spin spinning={isLoading}>
        <Form labelCol={{ span: 4 }} onFinish={onFinish} form={form}>
          <Item
            label="Company name"
            name="name"
            rules={[{ required: true, message: 'Enter company name!' }]}
          >
            <Input placeholder="Company name" disabled={!changing} />
          </Item>
          <Item
            label="Start time"
            name="start_time"
            rules={[{ required: true, message: 'Enter start time!' }]}
          >
            <DatePicker placeholder="Start time" disabled={!changing} />
          </Item>
          <Item
            label="End time"
            name="end_time"
            rules={[{ required: true, message: 'Enter end time!' }]}
          >
            <DatePicker placeholder="End time" disabled={!changing} />
          </Item>
          <Item label="Status" name="status">
            <Switch
              disabled={!changing}
              checked={status}
              onChange={(value) => changeStatus(value)}
            />
          </Item>
          <div className="df jc_fe">
            {changing ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={updateCompany.isLoading}
              >
                Save
              </Button>
            ) : (
              <Popconfirm
                title={'Are you sure to edit informations of company?'}
                placement="left"
                onConfirm={() => setChanging(true)}
                okText="Yes"
                cancelText="No"
              >
                <Button>Edit</Button>
              </Popconfirm>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default CompanyMain
