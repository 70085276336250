import { message } from 'antd'
import axios from 'axios'
import { AuthService } from '../services/AuthService'
import { LocalStorage } from '../services/LocalStorage'
import { USER_KEY } from '../utils/constants/localStorageKeys'
import { rootPaths } from '../utils/routes/root/rootPaths'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config) => {
  const token = AuthService.getToken()
  if (token && config.headers) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

$api.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error') {
    message.error("Internet tarmog'iga ulanishda xatolik yuz berdi", 5)
  }
  if (
    error.response?.status === 401 &&
    window.location.pathname !== rootPaths.login
  ) {
    LocalStorage.remove(USER_KEY)
    window.location.href = rootPaths.login
  } else {
    message.error(error.response?.data?.message, 5)
  }
  return Promise.reject(error)
})

export default $api
