import Clients from '../../../pages/clients-page/Clients'
import Commerce from '../../../pages/commerce-page/Commerce'
import Payments from '../../../pages/payment-page/Payments'
import Profile from '../../../pages/profile-page/Profile'
import { mainPaths } from './mainPaths'

export const mainRoutes = [
  {
    path: mainPaths.commerce,
    element: Commerce,
  },

  {
    path: mainPaths.payments,
    element: Payments,
  },
  {
    path: mainPaths.clients,
    element: Clients,
  },
  {
    path: mainPaths.profile,
    element: Profile,
  },
]
