export const queryNames = {
  COMPANIES: 'COMPANIES',
  DETAILED_COMPANY: 'DETAILED_COMPANY',
  COMPANY_ANALYTICS: 'COMPANY_ANALYTICS',
  CLIENTS: 'CLIENTS',
  STAFFS: 'STAFFS',
  SETTINGS_MAIN: 'SETTINGS_MAIN',
  PAYMENTS: 'PAYMENTS',
  CLIENT_WITH_BARCODE: 'CLIENT_WITH_BARCODE',
  ANALYTICS_DISCOUNT_VISIT: 'ANALYTICS_DISCOUNT_VISIT',
  ANALYTICS_DISCOUNT_PAYMENT: 'ANALYTICS_DISCOUNT_PAYMENT',
  ANALYTICS_DISCOUNT_CLIENT: 'ANALYTICS_DISCOUNT_CLIENT',
  ANALYTICS_DISCOUNT_COLLECTION: 'ANALYTICS_DISCOUNT_COLLECTION',
  GENERAL_PAYMENT_STATS: 'GENERAL_PAYMENT_STATS',
  GENERAL_VISIT_STATS: 'GENERAL_VISIT_STATS',
  LAST_PAYMENT: 'LAST_PAYMENT',
  BEST_ACCOUNTANT: 'BEST_ACCOUNTANT',
  LAST_PAYMENT_TABLE: 'LAST_PAYMENT_TABLE',
  SMS_KEYS: 'SMS_KEYS',
  SMS_TEMPLATE: 'SMS_TEMPLATE',
  SMS_HISTORY: 'SMS_HISTORY',
  PROFILE: 'PROFILE',
}
