import { Tabs } from 'antd'
import React from 'react'
import HistorySMS from '../../components/SMS/HistorySMS'
import SendSMS from '../../components/SMS/SendSMS'

import './SMS.scss'

const SMS: React.FC = () => {
  const { TabPane } = Tabs

  return (
    <Tabs defaultActiveKey="1" className="sms">
      <TabPane tab="SMS yuborish" key="1">
        <SendSMS />
      </TabPane>
      <TabPane tab="Yuborilgan smslar" key="2">
        <HistorySMS />
      </TabPane>
    </Tabs>
  )
}

export default SMS
