import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import $api from '../hooks/useAxiosInstance'
import {
  companyEndpoints,
  generalStatsEndpoints,
  smsEndpoints,
  staffEndpoints,
  statsEndpoints,
  urlsWithoutEndpoints,
} from '../utils/constants/api'
import { BestAccountant } from '../utils/models/analytics-page/BestAccountant'
import { DiscountClientResponse } from '../utils/models/analytics-page/DiscountClient'
import { DiscountCollectionData } from '../utils/models/analytics-page/DiscountCollection'
import { DiscountPaymentResponse } from '../utils/models/analytics-page/DiscountPayment'
import { DiscountVisitResponse } from '../utils/models/analytics-page/DiscountVisit'
import { GeneralPaymentResponse } from '../utils/models/analytics-page/GeneralPayment'
import { lastPaymentResponse } from '../utils/models/analytics-page/LastPayment'
import { LastPAymentTableResponse } from '../utils/models/analytics-page/LastPaymentsTable'
import {
  ClientDataWithCashback,
  ClientResponseData,
} from '../utils/models/clients-page/Client'
import {
  CompanyData,
  DetailedCompanyData,
  useCompanyAnalyticsData,
} from '../utils/models/companies-page/Companies'
import { PaymentsResponse } from '../utils/models/payments-page/Payments'
import { SettingsMainData } from '../utils/models/settings-page/SettingsMain'
import { StaffsData } from '../utils/models/settings-page/Staffs'
import { SmsHistoryResponse } from '../utils/models/SMS-page/SMS'
import { queryNames } from './queryNames'

//companies
export function useCompanies() {
  return useQuery<CompanyData[]>(
    [queryNames.COMPANIES],
    async () => {
      const res = await $api.get(companyEndpoints.INDEX)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}

// detailed company
export function useDetailedCompany(id: number) {
  return useQuery<DetailedCompanyData>(
    [queryNames.DETAILED_COMPANY, id],
    async () => {
      const res = await $api.get(companyEndpoints.INDEX + `/${id}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}

// company analytics
export function useCompanyAnalytics(id: number) {
  return useQuery<useCompanyAnalyticsData[]>(
    [queryNames.COMPANY_ANALYTICS, id],
    async () => {
      const res = await $api.get(companyEndpoints.ANALYTICS + `/${id}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// clients
export function useClients(searchText: string, page: number) {
  let url = urlsWithoutEndpoints.client
  if (searchText.length > 2) {
    url += `/${searchText}`
  }
  url += `?page=${page}`
  return useQuery<ClientResponseData>(
    [queryNames.CLIENTS, searchText, page],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// search client with barcode
export function useClientWithBarcode(
  barcode: string,
  setClientBalance: (balance: number) => void,
  setAvailablePaying: (availablePaying: boolean) => void,
  setClientForm: (clientForm: boolean) => void,
  setBarCode?: (barcode: string) => void
) {
  return useQuery<ClientDataWithCashback>(
    [queryNames.CLIENT_WITH_BARCODE, barcode],
    async () => {
      const res = await $api.get(
        urlsWithoutEndpoints.clientWithBarcode + `/${barcode}`
      )
      return res.data
    },
    {
      onSuccess: async (data) => {
        await setClientForm(true)
        if (data.id) {
          setAvailablePaying(true)
          document.getElementById('amount_input')?.focus()
          setClientBalance(Number(data.cashback))
        } else {
          document.getElementById('client_number_input')?.focus()
        }
      },
      refetchOnWindowFocus: false,
      enabled: barcode.length === 13,
      retry: false,
      onError: () => {
        if (setBarCode) {
          setBarCode('')
        }
      },
    }
  )
}

// staffs
export function useStaffs(searchText: string) {
  let url = `${staffEndpoints.INDEX}`
  if (searchText.length > 2) {
    url += `/${searchText}`
  }
  return useQuery<StaffsData[]>(
    [queryNames.STAFFS, searchText],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useMainSettings() {
  let url = `${urlsWithoutEndpoints.mainSettings}`
  return useQuery<SettingsMainData>(
    [queryNames.SETTINGS_MAIN],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// Payments
export function usePayments(searchText: string, page: number) {
  let url = urlsWithoutEndpoints.payment
  if (searchText && searchText.length > 2) {
    url += `/${searchText}`
  }
  url += `?page=${page}`
  return useQuery<PaymentsResponse>(
    [queryNames.PAYMENTS, searchText, page],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// Analytics => discount-visit
export function useAnalyticsDiscVisit() {
  let url = statsEndpoints.visitDiscount
  return useQuery<DiscountVisitResponse>(
    [queryNames.ANALYTICS_DISCOUNT_VISIT],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}
// Analytics => discount-payment

export function useAnalyticsDiscPayment() {
  let url = statsEndpoints.paymentDiscount
  return useQuery<DiscountPaymentResponse>(
    [queryNames.ANALYTICS_DISCOUNT_PAYMENT],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// Analytics => discount-collection
export function useAnalyticsDiscCollection() {
  let url = statsEndpoints.collectionDiscount
  return useQuery<DiscountCollectionData>(
    [queryNames.ANALYTICS_DISCOUNT_COLLECTION],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

// Analytics => discount-client

export function useAnalyticsDiscClient() {
  let url = statsEndpoints.clientDiscount
  return useQuery<DiscountClientResponse>(
    [queryNames.ANALYTICS_DISCOUNT_CLIENT],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

// general payment stats
export function useGeneralPaymentStats(date: any, type: 'date' | 'month' | 'year') {
  const dateType = () => {
    if (type === 'date') {
      return 'YYYY-MM-DD'
    } else if (type === 'month') {
      return 'YYYY-MM'
    } else if (type === 'year') {
      return 'YYYY'
    }
  }
  const payloadDate = [
    dayjs(date[0]).format(dateType()),
    dayjs(date[1]).format(dateType()),
  ]
  let url = `${generalStatsEndpoints.payment}/${payloadDate[0]}/${payloadDate[1]}`
  return useQuery<GeneralPaymentResponse>(
    [queryNames.GENERAL_PAYMENT_STATS, date],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

//general visit stats
export function useGeneralVisitStats(date: any, type: 'date' | 'month' | 'year') {
  const dateType = () => {
    if (type === 'date') {
      return 'YYYY-MM-DD'
    } else if (type === 'month') {
      return 'YYYY-MM'
    } else if (type === 'year') {
      return 'YYYY'
    }
  }
  const payloadDate = [
    dayjs(date[0]).format(dateType()),
    dayjs(date[1]).format(dateType()),
  ]
  let url = `${generalStatsEndpoints.visit}/${payloadDate[0]}/${payloadDate[1]}`
  return useQuery<GeneralPaymentResponse>(
    [queryNames.GENERAL_VISIT_STATS, date],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

//  lastPayment
export function useLastPayment() {
  let url = statsEndpoints.lastPayment
  return useQuery<lastPaymentResponse>([queryNames.LAST_PAYMENT], async () => {
    const res = await $api.get(url)
    return res.data
  })
}

//best Accountant
export function useBestAccountant(dateChange: 'all' | 'month' | 'year') {
  let url = `${statsEndpoints.bestAccountant}/${dateChange}`
  return useQuery<BestAccountant>(
    [queryNames.BEST_ACCOUNTANT, dateChange],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

//last payments table
export function useLastPaymentTable(page: number) {
  let url = `${statsEndpoints.lastPaymentTable}?page=${page}`
  return useQuery<LastPAymentTableResponse>(
    [queryNames.LAST_PAYMENT_TABLE, page],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

// sms keys
export function useSmsKeys() {
  return useQuery<string[]>(
    [queryNames.SMS_KEYS],
    async () => {
      const res = await $api.get(smsEndpoints.KEYS)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// sms template
export function useSmsTemplate() {
  return useQuery<{ message: string }>(
    [queryNames.SMS_TEMPLATE],
    async () => {
      const res = await $api.get(smsEndpoints.INDEX)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// sms history
export function useSmsHistory(page: number) {
  return useQuery<SmsHistoryResponse>(
    [queryNames.SMS_HISTORY, page],
    async () => {
      const res = await $api.get(smsEndpoints.HISTORY + `?page=${page}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// profile
export function useProfile() {
  return useQuery<{ id: number; name: string; login: string }>(
    [queryNames.PROFILE],
    async () => {
      const res = await $api.get(urlsWithoutEndpoints.profile)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}
