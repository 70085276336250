import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Input, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { usePayments } from '../../queries/queries'
import { PaymentsData } from '../../utils/models/payments-page/Payments'

import './Payments.scss'

const Payments: React.FC = () => {
  const [form] = Form.useForm()
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(1)
  const { data, isLoading } = usePayments(searchText, page)
  const timerRef = React.useRef<any>()

  return (
    <>
      <div className="searchPayments white_box  ">
        <p>
          <span>Mijoz izlash</span>
          <QuestionCircleOutlined />
          <span className="third">:</span>
        </p>
        <Form form={form}>
          <Form.Item name="searchText">
            <Input
              onChange={(e) => {
                clearTimeout(timerRef.current)
                timerRef.current = setTimeout(() => {
                  setSearchText(e.target.value.length > 2 ? e.target.value : '')
                }, 500)
              }}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </div>
      <div className="white_box payments">
        <Table
          pagination={{
            pageSize: 5,
            total: data?.total,
            onChange: (page: number) => {
              setPage(page)
            },
            current: data?.current_page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            style: {
              marginRight: '1rem',
            },
          }}
          className="tablePayments"
          dataSource={data?.data}
          loading={isLoading}
          rowKey={(record) => record.payment_id}
        >
          <Table.Column
            title="Sana"
            align="center"
            render={(record: PaymentsData) =>
              dayjs(record.created_at * 1000).format('DD.MM.YYYY HH:mm')
            }
          />
          <Table.Column dataIndex={'car_number'} title="Mijoz" align="center" />
          <Table.Column
            render={(record: PaymentsData) => (
              <span>{(record.amount + record.cashback)?.toLocaleString()} so'm</span>
            )}
            align="right"
            title="Umumiy to'lov"
          />
          <Table.Column
            title="Mijozdan"
            align="right"
            render={(record) => <span>{record.amount?.toLocaleString()} so'm</span>}
          />
          <Table.Column
            title="Balansdan"
            align="right"
            render={(record) => (
              <span>{record.cashback?.toLocaleString()} so'm</span>
            )}
          />
          <Table.Column
            className="status"
            title="Holat"
            align="center"
            render={(record: PaymentsData) => (
              <Tag color={record.status === 'active' ? 'green' : 'red'}>
                {record.status === 'active' ? 'muvafaqiyatli' : "o'chirilgan"}
              </Tag>
            )}
          />
        </Table>
      </div>
    </>
  )
}

export default Payments
