import Analytics from '../../../pages/analitic-page/Analytics'
import Settings from '../../../pages/settings-page/Settings'
import SMS from '../../../pages/sms-page/SMS'
import { mainPaths } from './mainPaths'

export const protectedRoutes = [
  {
    path: mainPaths.analytics,
    element: Analytics,
  },
  {
    path: mainPaths.sms,
    element: SMS,
  },
  {
    path: mainPaths.settings,
    element: Settings,
  },
]
