import { Navigate, Outlet } from 'react-router-dom'
import { AuthService } from '../../../services/AuthService'
import { rootPaths } from '../root/rootPaths'
import { mainPaths } from './mainPaths'

const NotDeveloperRoutes = () => {
  if (!AuthService.isDeveloper()) {
    return <Outlet />
  } else {
    return <Navigate to={rootPaths.main + mainPaths.companies} />
  }
}

export default NotDeveloperRoutes
