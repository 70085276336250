import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Popconfirm, Spin, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClientsModal from '../../components/clients/ClientsModal'
import { useDeleteClient } from '../../queries/mutations'
import { useClients } from '../../queries/queries'
import { modalTypes } from '../../utils/constants/modalTypes'
import { ClientData, ClientModalData } from '../../utils/models/clients-page/Client'

import './Clients.scss'

const Clients: React.FC = () => {
  const [form] = Form.useForm()
  const timerRef = React.useRef<any>()
  const [searchText, setSearchText] = useState('')
  const { t } = useTranslation()
  const { Column } = Table
  const [page, setPage] = useState<number>(1)
  const { data, isLoading } = useClients(searchText, page)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState<ClientModalData>({ type: '' })
  const deleteClientMutation = useDeleteClient()

  // create client
  const createClient = () => {
    setModalData({ type: modalTypes.CREATE })
    setModalVisible(true)
  }

  // update client
  const updateClient = (record: ClientData) => {
    setModalData({ type: modalTypes.UPDATE, data: record })
    setModalVisible(true)
  }

  // delete client
  const deleteClient = (record: ClientData) => {
    deleteClientMutation.mutate(record.id)
  }

  return (
    <div className="clients">
      <div className="white_box search">
        <div className="search_left">
          <p>
            <span>{t('clients.client_name')}</span>
            <QuestionCircleOutlined />
            <span className="third">:</span>
          </p>
          <Form form={form}>
            <Form.Item name="searchText">
              <Input
                width={400}
                onChange={(e) => {
                  clearTimeout(timerRef.current)
                  timerRef.current = setTimeout(() => {
                    setSearchText(e.target.value.length > 2 ? e.target.value : '')
                  }, 500)
                }}
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="search_right">
          <Button type="primary" onClick={createClient}>
            + {t("Yangi qo'shish")}
          </Button>
        </div>
      </div>
      <div className="white_box">
        <Table
          loading={isLoading}
          dataSource={data?.data}
          className="clients_table"
          rowKey={(record) => record.id}
          pagination={{
            pageSize: 5,
            total: data?.total,
            onChange: (page: number) => {
              setPage(page)
            },
            current: data?.current_page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            style: {
              marginRight: '1rem',
            },
          }}
        >
          <Column title="№" render={(_, __, index) => index + 1} />
          <Column title={t('clients.client')} dataIndex="car_number" width={500} />
          <Column title={t('clients.barcode')} dataIndex="barcode" align="center" />
          <Column
            title={t('Tizimga kiritildi')}
            align="center"
            render={(record) => (
              <span>
                {dayjs(record.created_at * 1000).format('DD.MM.YYYY HH:mm')}
              </span>
            )}
          />
          <Column
            title={() => (
              <>
                <span>{t('Action')}</span>
                &nbsp;
                {deleteClientMutation.isLoading && (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                  />
                )}
              </>
            )}
            align="center"
            render={(record) => (
              <div className="more">
                <Button onClick={() => updateClient(record)}>
                  <EditOutlined />
                </Button>
                <Popconfirm
                  title={t('clients.delete_client')}
                  onConfirm={() => deleteClient(record)}
                  okText={t('Ha')}
                  cancelText={t("Yo'q")}
                  placement="left"
                >
                  <Button>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
            )}
          />
        </Table>
      </div>
      <ClientsModal
        visible={modalVisible}
        setVisible={setModalVisible}
        data={modalData}
      />
    </div>
  )
}

export default Clients
