import Login from '../../../pages/login-page/Login'
import MainRouter from '../../../routing/MainRouter'
import { Redirector } from '../../../routing/Redirector'
import { rootPaths } from './rootPaths'

export const rootRoutes = [
  {
    path: rootPaths.login,
    element: Login,
  },
  {
    path: rootPaths.main,
    element: MainRouter,
  },
  {
    path: rootPaths.index,
    element: Redirector,
  },
]
