import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/svg/Logo'
import { useLogin } from '../../queries/mutations'
import { Redirector } from '../../routing/Redirector'
import { LoginData } from '../../utils/models/login-page/Login'

import './Login.scss'

const Login: React.FC = () => {
  const { Item } = Form
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const login = useLogin(form)

  // login
  const onFinish = (fields: LoginData) => {
    login.mutate(fields)
  }

  return (
    <div className="login">
      <div className="login_top">
        <div className="logo">
          <Logo />
          <span>KESHBEK</span>
        </div>
        <p>Biznesingiz uchun cashback tizimi</p>
      </div>
      <div className="login_center">
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <Item
            name="login"
            rules={[{ required: true, message: t('Loginingizni kiriting!') }]}
          >
            <Input placeholder={t('login')} prefix={<UserOutlined />} />
          </Item>
          <Item
            name="password"
            rules={[{ required: true, message: t('Parolingizni kiriting!') }]}
          >
            <Input.Password placeholder={t('parol')} prefix={<LockOutlined />} />
          </Item>
          <Button loading={login.isLoading} type="primary" htmlType="submit">
            {t('Kirish')}
          </Button>
        </Form>
      </div>
      <div className="login_bottom">
        <p className="copyright">©2022 Produced by SOFTEX • Uzbekistan, Navoi</p>
        <p className="call">Call center: +99891 253 26 03</p>
      </div>
      <Redirector />
    </div>
  )
}

export default Login
