import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageNotFound from '../pages/page-not-found/PageNotFound'
import { rootRoutes } from '../utils/routes/root/rootRoutes'

const RootRouter: React.FC = () => {
  return (
    <div className="app">
      <Routes>
        {rootRoutes.map((route) => (
          <Route
            path={`${route.path}/*`}
            element={<route.element />}
            key={route.path}
          />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

export default RootRouter
