import { Tabs } from 'antd'
import React, { useState } from 'react'
import CompanyAnalytics from './CompanyAnalytics'
import CompanyMain from './CompanyMain'

const DetailedCompany: React.FC = () => {
  const [title, setTitle] = useState('')
  const { TabPane } = Tabs

  return (
    <div className="companies_detailed">
      <div className="title">
        <p>{title}</p>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab={'Main'} key="1">
          <CompanyMain setTitle={setTitle} />
        </TabPane>
        <TabPane tab={'Analytics'} key="2">
          <CompanyAnalytics />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailedCompany
