import { Table, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useSmsHistory } from '../../queries/queries'

const HistorySMS: React.FC = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useSmsHistory(page)
  const { Column } = Table

  // tag color
  const tagColor = (status: string) => {
    if (status === 'active') {
      return 'green'
    } else return 'red'
  }

  // tag text
  const tagText = (status: string) => {
    if (status === 'active') {
      return 'Yuborildi'
    } else return 'Yuborilmadi'
  }

  return (
    <div className="white_box sms_history">
      <Table
        dataSource={data?.data}
        loading={isLoading}
        rowKey={(record) => record.client_sms_id}
        pagination={{
          pageSize: 10,
          total: data?.total,
          onChange: (page: number) => {
            setPage(page)
          },
          current: data?.current_page,
          hideOnSinglePage: true,
          showSizeChanger: false,
          style: {
            marginRight: '1rem',
          },
        }}
      >
        <Column title="№" render={(_, __, index) => index + 1} align="center" />
        <Column title="Mijoz" dataIndex="car_number" align="center" />
        <Column title="Telefon raqami" dataIndex="phone_number" align="center" />
        <Column
          title="Xabar matni"
          render={(record) => (
            <Tooltip title={<span>{record.message}</span>} placement="bottom">
              <span>
                {record.message.slice(0, 80)}
                {record.message.length > 80 && <span>...</span>}
              </span>
            </Tooltip>
          )}
        />
        <Column
          title="Vaqti"
          render={(record) =>
            dayjs(record.created_at * 1000).format('DD.MM.YYYY HH:mm')
          }
          align="center"
        />
        <Column
          title="Status"
          render={(record) => (
            <Tag color={tagColor(record.status)}>{tagText(record.status)}</Tag>
          )}
          align="center"
        />
      </Table>
    </div>
  )
}

export default HistorySMS
