import { Layout, Menu } from 'antd'
import {
  UserOutlined,
  CheckCircleOutlined,
  BarChartOutlined,
  FormOutlined,
  HighlightOutlined,
  MessageOutlined,
  LockOutlined,
} from '@ant-design/icons'
import { useState } from 'react'
import Logo from '../../assets/svg/Logo'
import { Link, useLocation } from 'react-router-dom'
import { mainPaths } from '../../utils/routes/main/mainPaths'
import { rootPaths } from '../../utils/routes/root/rootPaths'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Sidebar.scss'
import ExitIcon from '../../assets/svg/ExitIcon'
import { LocalStorage } from '../../services/LocalStorage'
import { USER_KEY } from '../../utils/constants/localStorageKeys'
import { AuthService } from '../../services/AuthService'

const { Sider } = Layout

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const pathname = useLocation().pathname.replace(rootPaths.main, '')

  // collapse
  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed)
  }

  // to main page
  const toMain = () => {
    navigate(rootPaths.main + mainPaths.commerce)
  }

  // default selected
  const defaultSelected = () => {
    if (pathname === mainPaths.commerce) {
      return '1'
    } else if (pathname === mainPaths.clients) {
      return '2'
    } else if (pathname === mainPaths.payments) {
      return '3'
    } else if (pathname === mainPaths.sms) {
      return '4'
    } else if (pathname === mainPaths.analytics) {
      return '5'
    } else if (pathname === mainPaths.settings) {
      return '6'
    } else if (pathname.includes(mainPaths.companies)) {
      return '7'
    }
    return ''
  }

  // exit
  const exit = () => {
    LocalStorage.remove(USER_KEY)
    navigate(rootPaths.login)
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="sidebar"
    >
      <div className={`${collapsed ? 'collapsed' : ''} logo`} onClick={toMain}>
        <Logo />
        <span>KESHBEK</span>
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={[defaultSelected()]}
        selectedKeys={[defaultSelected()]}
        activeKey={defaultSelected()}
        mode="inline"
      >
        {!AuthService.isDeveloper() ? (
          <>
            <Menu.Item icon={<CheckCircleOutlined />} key="1">
              <Link to={rootPaths.main + mainPaths.commerce}>{t('Savdo')}</Link>
            </Menu.Item>
            <Menu.Item icon={<UserOutlined />} key="2">
              <Link to={rootPaths.main + mainPaths.clients}>{t('Mijozlar')}</Link>
            </Menu.Item>
            <Menu.Item icon={<HighlightOutlined />} key="3">
              <Link to={rootPaths.main + mainPaths.payments}>{t("To'lovlar")}</Link>
            </Menu.Item>
            {AuthService.isBoss() && (
              <>
                <Menu.Item icon={<MessageOutlined />} key="4">
                  <Link to={rootPaths.main + mainPaths.sms}>SMS</Link>
                </Menu.Item>
                <Menu.Item icon={<BarChartOutlined />} key="5">
                  <Link to={rootPaths.main + mainPaths.analytics}>
                    {t('Analitika')}
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="6">
                  <Link to={rootPaths.main + mainPaths.settings}>
                    {t('Sozlash')}
                  </Link>
                </Menu.Item>
              </>
            )}
          </>
        ) : (
          <Menu.Item key="7" icon={<LockOutlined />}>
            <Link to={rootPaths.main + mainPaths.companies}>Companies</Link>
          </Menu.Item>
        )}
      </Menu>
      <div className={`${collapsed ? 'collapsed' : ''} exit`} onClick={exit}>
        <span className="exit_svg">
          <ExitIcon />
        </span>
        <span className="text">Chiqish</span>
      </div>
    </Sider>
  )
}

export default Sidebar
