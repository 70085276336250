import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateUpdateClient } from '../../queries/mutations'
import { modalTypes } from '../../utils/constants/modalTypes'
import {
  ClientModalData,
  ClientPayloadData,
} from '../../utils/models/clients-page/Client'
import ReactInputMask from 'react-input-mask'

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  data: ClientModalData
}

const ClientsModal: React.FC<IProps> = ({ visible, setVisible, data }) => {
  const [form] = Form.useForm()
  const { Item } = Form
  const { t } = useTranslation()
  const createUpdateClient = useCreateUpdateClient(setVisible)

  // initial values
  useEffect(() => {
    if (data.type === modalTypes.UPDATE && data?.data) {
      form.setFieldsValue({
        name: data.data.name,
        phone_number: data.data.phone_number,
        car_number: data.data.car_number,
        barcode: data.data.barcode,
      })
    }
  }, [data, form])

  // on cancel
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  // modal title
  const modalTitle = () => {
    if (data.type === modalTypes.CREATE) {
      return t('clients.create_client')
    } else if (data.type === modalTypes.UPDATE) {
      return t('clients.update_client')
    }
    return ''
  }

  // submit button text
  const submitButtonText = () => {
    if (data.type === modalTypes.CREATE) {
      return t("Qo'shish")
    } else if (data.type === modalTypes.UPDATE) {
      return t('Yangilash')
    }
  }

  // on finish
  const onFinish = (values: ClientPayloadData) => {
    let payloadData = values
    if (data?.data?.id) {
      payloadData = { ...payloadData, id: data?.data?.id }
    }
    createUpdateClient.mutate(payloadData)
  }

  return (
    <Modal
      title={modalTitle()}
      visible={visible}
      centered
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('Bekor qilish')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="client_form"
          loading={createUpdateClient.isLoading}
        >
          {submitButtonText()}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        id="client_form"
        layout="vertical"
        autoComplete="off"
      >
        <Item
          name="phone_number"
          label={t('clients.phone_number')}
          rules={[
            {
              required: true,
              message: t('clients.errors.phone_number'),
            },
          ]}
        >
          <ReactInputMask mask="+\9\98 99 999 99 99">
            {() => <Input />}
          </ReactInputMask>
        </Item>
        <Item
          name="car_number"
          label={t('clients.car_number')}
          rules={[
            {
              required: true,
              message: t('clients.errors.car_number'),
            },
          ]}
        >
          <ReactInputMask mask="99 ******">{() => <Input />}</ReactInputMask>
        </Item>
        <Item
          name="barcode"
          label={t('clients.barcode')}
          rules={[
            {
              required: true,
              message: t('clients.errors.barcode'),
            },
          ]}
        >
          <Input />
        </Item>
        <Item name="name" label={t('Name')}>
          <Input />
        </Item>
      </Form>
    </Modal>
  )
}

export default ClientsModal
