import React from 'react'

import './PageNotFound.scss'

const PageNotFound: React.FC = () => {
  return (
    <div className="pnf">
      <h1>Page not found</h1>
    </div>
  )
}

export default PageNotFound
