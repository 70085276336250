import React from 'react'

const InfoIcon: React.FC = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1808 29.7642C14.1808 31.3225 15.4417 32.5833 17 32.5833C18.5583 32.5833 19.8192 31.3225 19.8192 29.7642H14.1808ZM17 8.5C20.91 8.5 24.0833 11.6733 24.0833 15.5833V25.5H9.91667V15.5833C9.91667 11.6733 13.09 8.5 17 8.5ZM17 2.125C15.8242 2.125 14.875 3.07417 14.875 4.25V5.9075C10.4267 6.87083 7.08333 10.8375 7.08333 15.5833V24.0833L4.25 26.9167V28.3333H29.75V26.9167L26.9167 24.0833V15.5833C26.9167 10.8375 23.5733 6.87083 19.125 5.9075V4.25C19.125 3.07417 18.1758 2.125 17 2.125ZM15.5833 11.3333H18.4167V17H15.5833V11.3333ZM15.5833 19.8333H18.4167V22.6667H15.5833V19.8333Z"
        fill="#323232"
      />
      <circle
        cx="26"
        cy="8"
        r="7.7"
        fill="#FF0000"
        stroke="white"
        strokeWidth="0.6"
      />
    </svg>
  )
}

export default InfoIcon
