import { Button, Form, Input, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useUpdateProfile } from '../../queries/mutations'
import { useProfile } from '../../queries/queries'

import './Profile.scss'

const Profile: React.FC = () => {
  const { Item } = Form
  const [form] = Form.useForm()
  const [changingPassword, setChangingPassword] = useState(false)
  const { data, isLoading } = useProfile()
  const updateProfile = useUpdateProfile()

  // initial form values
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        login_org: data.login,
      })
    }
  }, [data, form])

  // on finish
  const onFinish = (values: {
    name: string
    login_org: string
    password_org?: string
    confirm?: string
  }) => {
    const updateData = {
      name: values.name,
      login: values.login_org,
      password: values.password_org,
      reset_password: values.confirm,
    }
    updateProfile.mutate(updateData)
  }

  // enable/disable password fields
  const enableChangingPassword = () => {
    setChangingPassword((prev) => !prev)
  }

  return (
    <div className="white_box profile">
      <Spin spinning={isLoading}>
        <Form form={form} autoComplete="off" onFinish={onFinish} layout="vertical">
          <div className="fake">
            <Input placeholder="login" name="login" />
            <Input.Password placeholder="password" name="password" />
          </div>
          <Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Ismingizni kiriting!',
              },
            ]}
            label={'Ismingiz'}
          >
            <Input />
          </Item>
          <Item
            name="login_org"
            rules={[
              {
                required: true,
                message: 'Loginingizni kiriting!',
              },
              {
                min: 5,
                message: "Login kamida 5ta belgidan iborat bo'lishi kerak!",
              },
            ]}
            label={'Login'}
          >
            <Input />
          </Item>

          {changingPassword && (
            <>
              <Item
                name="password_org"
                rules={[
                  {
                    required: true,
                    message: 'Parolingizni kiriting!',
                  },
                  {
                    min: 5,
                    message: "Parol kamida 5ta belgidan iborat bo'lishi kerak!",
                  },
                ]}
                hasFeedback
                label={'Parol'}
              >
                <Input.Password />
              </Item>

              <Item
                name="confirm"
                dependencies={['password_org']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Parolingizni tasdiqlang!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password_org') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('Kiritgan parollaringiz mos kelmadi!')
                      )
                    },
                  }),
                ]}
                label={'Parolni tasdiqlash'}
              >
                <Input.Password />
              </Item>
            </>
          )}
          <p className="enableChangingPassword" onClick={enableChangingPassword}>
            {!changingPassword ? "Parolni o'zgartirish >" : "Parolni o'zgartirish"}
          </p>
          <div className="df jc_fe">
            <Button
              type="primary"
              htmlType="submit"
              loading={updateProfile.isLoading}
            >
              Saqlash
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default Profile
