import React from "react";

function ArrowBottom() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49004 0.101562H0.510352C0.241016 0.101562 0.0906256 0.385937 0.257423 0.580078L4.74727 5.78633C4.87578 5.93535 5.12324 5.93535 5.25313 5.78633L9.74297 0.580078C9.90977 0.385937 9.75938 0.101562 9.49004 0.101562Z"
        fill="#FF4D4F"
      />
    </svg>
  );
}

export default ArrowBottom;
