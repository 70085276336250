import React from 'react'
import Paying from './Paying'
import PaymentsInCommerce from './PaymentsInCommerce'

interface IProps {
  barcode: string
  clientBalance: number
  setBarcode: (barcode: string) => void
  availablePaying: boolean
}

const CommerceRight: React.FC<IProps> = ({
  barcode,
  clientBalance,
  setBarcode,
  availablePaying,
}) => {
  return (
    <div className="commerce_right">
      {barcode.length === 13 && (
        <div className="commerce_right_top">
          <Paying
            barcode={barcode}
            clientBalance={clientBalance}
            setBarcode={setBarcode}
            availablePaying={availablePaying}
          />
        </div>
      )}
      <div className="commerce_right_bottom">
        <PaymentsInCommerce barcode={barcode} />
      </div>
    </div>
  )
}

export default CommerceRight
