import { Button, Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyModal from '../../../components/companies/CompanyModal'
import { useCompanies } from '../../../queries/queries'
import { Status } from '../../../utils/constants/Status'
import { mainPaths } from '../../../utils/routes/main/mainPaths'
import { rootPaths } from '../../../utils/routes/root/rootPaths'

import './Companies.scss'

const Companies: React.FC = () => {
  const { data, isLoading } = useCompanies()
  const { Column } = Table
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  // status color
  const statusColor = (status: string) => {
    if (status === Status.ACTIVE) {
      return 'green'
    } else if (status === Status.PASSIVE) {
      return 'red'
    }
  }

  // status text
  const statusText = (status: string) => {
    if (status === Status.ACTIVE) {
      return 'Active'
    } else if (status === Status.PASSIVE) {
      return 'Inactive'
    }
    return '---'
  }

  // open modal
  const openModal = () => {
    setVisible(true)
  }

  return (
    <div className="companies">
      <div className="white_box df jc_fe companies_top">
        <Button type="primary" onClick={openModal}>
          + Create new company
        </Button>
      </div>
      <div className="white_box ">
        <Table
          dataSource={data}
          pagination={false}
          rowKey={(record) => record.station_id}
          className="companies_table"
          onRow={(record) => ({
            onClick: () => {
              navigate(
                rootPaths.main + mainPaths.companies + `/${record.station_id}`
              )
            },
          })}
          loading={isLoading}
        >
          <Column
            title="№"
            align="center"
            width={60}
            render={(_, __, index) => index + 1}
          />
          <Column title="Name" dataIndex="station_name" />
          <Column title="Director" dataIndex="direktor_name" />
          <Column
            title="Lifetime (day)"
            align="center"
            render={(record) => (
              <span className={`${record.lifetime < 0 ? 'red' : 'green'} lifetime`}>
                {record.lifetime < 0 ? record.lifetime : '+' + record.lifetime}
              </span>
            )}
          />
          <Column
            title="Status"
            align="center"
            render={(record) => (
              <Tag color={statusColor(record.status)}>
                {statusText(record.status)}
              </Tag>
            )}
          />
        </Table>
      </div>
      <CompanyModal visible={visible} setVisible={setVisible} />
    </div>
  )
}

export default Companies
